//import $api from "../../utils/axios";
import React, { Component } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'rc-checkbox/assets/index.css';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Country }  from 'country-state-city';
//import { useParams } from 'react-router-dom';

export default class EditVatgst extends Component {
    state = {
        fullName: '',
        email: '',
        password: '',
        hotelName: '',
        hotelDescription: '',
        microStay: [],
        hotelRating: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        commRate: 0,

        Amenities: [],

        sameOwnerContact: false,
        sameOwnerManager: false,

        contactFullName: '',
        contactFirstName: '',
        contactLastName: '',
        contactPosition: '',
        contactNumber: '',
        contactEmail: '',

        managerFullName: '',
        managerFirstName: '',
        managerLastName: '',
        managerPosition: '',
        managerNumber: '',
        managerEmail: '',

        fullNameError: '',
        emailError: '',
        passwordError: '',

        propertyID: '',

        hotelMultiImage: [],
        hotelOptions: [],
        hotelSelectName: '',
        hotelSelectId: '',
        currencyFor: '',
        vat: '',
        vatgstId: '',
        shortName: '',
        currencyFullName: '',
        exchangeRate: '',
    }
    async componentDidMount() {
        let tokenLoad = localStorage.getItem("token");
        //const resHotel = await $api.get("/archives/hotels");
        const countryListAll = Country.getAllCountries();
        this.setState({
            hotelOptions: countryListAll,
            hotelSelectName: countryListAll[0].name,
            hotelSelectId: countryListAll[0].flag,
            currencyFor: countryListAll[0].currency,
            vatgstId: this.props.match.params.vatId
        })

        let parameters = {
            "vatgstId": this.props.match.params.vatId
        };
        await axios
          .post(`${process.env.REACT_APP_API_URL}/vatgst/getBannerById`, parameters, {
            headers: {
              Authorization: `Bearer ${tokenLoad}`,
            },
          })
          .then((response) => {
           this.setState({
            hotelSelectName: response.data.countryname,
            hotelSelectId: response.data.countryflag,
            currencyFor: response.data.currencyFor,
            vat: response.data.vat,
            vatgstId: response.data._id,
            shortName: response.data.shortName,
            currencyFullName: response.data.currencyFullName,
            exchangeRate: response.data.exchangeRate
          });

          })
          .catch((error) => {
            console.log(error);
          });
      }

    handleOptionChange = async (event, selectedOption) => {
    this.setState({
        hotelSelectName: selectedOption.name,
        hotelSelectId: selectedOption.flag,
        currencyFor: selectedOption.currency
    })
        //setCurrentTab(res.data[0]._id);
      };
    onChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    submitFunction = async (e) => {
        e.preventDefault();

                const { hotelSelectName, hotelSelectId, currencyFor, vat, shortName, currencyFullName, exchangeRate } = this.state;
                let parameters = {
                    "countryname": hotelSelectName,
                    "countryflag": hotelSelectId,
                    "currencyFor": currencyFor,
                    "vat": vat,
                    "isDeleted": false,
                    "vatgstId": this.props.match.params.vatId,
                    "shortName": shortName,
                    "currencyFullName": currencyFullName,
                    "exchangeRate": exchangeRate
                };
                await axios.post(`${process.env.REACT_APP_API_URL}/vatgst/editvat`, parameters, {
                    headers: {
                        'Content-type': 'application/json',
                    },
                })
                .then(async hotelResponse => {
                    if (hotelResponse.status === "201" || hotelResponse.status === 201) {
                        toast.success("Data updated successfully", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });             
                    }
                })
                .catch(err => {
                    toast.error(err, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
    }

    render() {
        const { history } = this.props
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Countries</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li><a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li><a href={() => null} onClick={() => history.push("/vatgst")}>Country Management</a>
                            </li>
                            <li className="active">Add Country</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <form onSubmit={this.submitFunction}>
                            <div className="panel">
                                <div className="panel-header ">
                                    <h3><strong>Edit Country</strong> </h3>
                                </div>
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Select Country</h5>
                                        <hr />
                                    <div className="form-group">
          <Autocomplete
            id="combo-box-demo"
            options={this.state.hotelOptions}
            getOptionLabel={(option) => option.name}
            disabled
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={this.state.hotelSelectName} />}
          />
        </div>
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>Currency</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                    <input type="text" required name="currencyFor" className="form-control" placeholder="Enter the currency here" aria-required="true" onChange={this.onChange} defaultValue={this.state.currencyFor} />
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>Currency Full Name</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                    <input type="text" required name="currencyFullName" className="form-control" placeholder="Enter the currency full name here" aria-required="true" onChange={this.onChange} defaultValue={this.state.currencyFullName} />
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>Short Display Currency</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                    <input type="text" required name="shortName" className="form-control" placeholder="Enter the currency short display name here" aria-required="true" onChange={this.onChange} defaultValue={this.state.shortName} />
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>Exchange Rate</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                    <input type="number" step="any" required name="exchangeRate" className="form-control" placeholder="Enter the exchange rate here" aria-required="true" onChange={this.onChange} defaultValue={this.state.exchangeRate} />
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>VAT/GST( % )</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                    <input type="number" required name="vat" className="form-control" placeholder="Enter VAT/GST % here" aria-required="true" onChange={this.onChange} defaultValue={this.state.vat} />
                                    </div>
                                </div>

                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger">Submit</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={() => history.push("/vatgst")}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
