import React from 'react';

export default function ReservationBox(props) {
    const { dataSets } = props;
    return (
        <div>
            <div className="row" style={{ paddingTop: 20 }}>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            Total Bookings
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].bookings}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            Avg. Rate
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].avgRate}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            Total Revenue
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].totalRevenue}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            No Shows
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].NoShow}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ paddingBottom: 20, textAlign: 'center' }}>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            3 Hour Bookings
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].threeHourBooking}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            6 Hour Bookings
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].sixHourBooking}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 pl-0">
                    <div className="panel">
                        <div className="panel-header bold text-center">
                            12 Hour Bookings
                        </div>
                        <div className="panel-content text-center text-report-primary">
                            {dataSets[0].twelveHourBooking}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}