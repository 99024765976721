import React, { Component } from "react";
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'FULL NAME',
        selector: row => row.fullName,
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: row => row.email,
        sortable: true,
        wrap: true,
    },
    {
        name: 'USER TYPE',
        selector: row => row.userType,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        grow: 4,
    },
];

export default class UserListing extends Component {
    state = {
        records: [],
        loading: true,
        alertShow: false,
        alertBoxTitle: '',
        alertBoxType: '',
        alertBoxData: null,
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/user/employeeList`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let array = [];
            response.data.forEach(element => {
                let userType = '';
                if (element.accessLevel === "STANDARD") {
                    userType = 'Reservation Contact';
                } else if (element.accessLevel === "MANAGER") {
                    userType = 'Manager';
                } else if (element.accessLevel === "REGIONALMANAGER") {
                    userType = 'Hotel Manager';
                } else if (element.accessLevel === "GROUPMANAGER") {
                    userType = 'Revenue Manager';
                } else {
                    userType = 'Admin';
                }
                if(userType === 'Admin'){
                    array.push({
                        fullName: element.fullName,
                        email: element.email,
                        userType,
                        action:
                            <div className="text-center">
                                <button className="btn btn-danger" onClick={() => this.showAlertBox(element, 'Reset')}>Reset Password</button>
                                <button className="btn btn-primary" onClick={() => this.hotelAdminRedirectLink(element._id)}>Sign into Account</button>
                                <a className="btn btn-default" href={`/property/${element._id}`}>View Properties</a>
                            </div>
                    })
                }
            });
            this.setState({
                records: array,
                loading: false
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    showAlertBox = (value, alertType) => {
        let title = alertType === 'Reset' ? `Please confirm you wish to reset guest password for ${value.firstName} ${value.lastName}` : `${alertType} ?`;
        this.setState({
            alertBoxData: value,
            alertBoxType: alertType,
            alertBoxTitle: title,
            alertShow: true,
        })
    }
    onCancel(){
        this.setState({
            alertShow: false,
            alertBoxData: [],
            alertBoxType: '',
            alertBoxTitle: '',
        })
    }
    confirmFunc() {
        const { alertBoxData } = this.state;
        this.setState({
            alertShow: false
        }, async () => {
            const details = {
                "email": alertBoxData.email,
                "userId": alertBoxData._id
            };
            let token = await localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_URL}/user/resetPassword`, details, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then(response => {
                if (response.data === 'OK') {
                    toast.success('Password Reset Email sent Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.componentDidMount();
                } else {
                    toast.error('Something went wrong, Please try again...', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            })
            .catch(error => {
                console.log(error);
            })
        })
    }

    async hotelAdminRedirectLink(userId) {
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/user/generateToken`, { userId }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                const url = `${process.env.REACT_APP_HOTEL_ADMIN_URL}/superAdminAccess/${response.data.token}/${token}`;
                const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            } else {
                toast.error('Something went wrong, Please try again...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(error => {
            console.log(error);
            toast.error('Something went wrong, Please try again...', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    render() {
        const { history } = this.props
        const { loading, records, alertShow, alertBoxTitle } = this.state;
        return (
            <div>
                <SweetAlert
                    show={alertShow}
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="danger"
                    title={alertBoxTitle}
                    onConfirm={() => this.confirmFunc()}
                    onCancel={() => this.onCancel()}
                    focusCancelBtn
                />
                <div className="header">
                    <h2><strong>Manage</strong> Users</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Users Management</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <h3><strong>User Information</strong> </h3>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading}  filterDefaultText="Filter By Full Name, Email, User Type" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}