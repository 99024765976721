import React, { memo, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Container } from "@mui/system";
import {
    Box,
    Breadcrumbs,
    Link,
    Button,
    Grid,
    FormControl,
    TextField,
    Typography,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Chip,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { map } from "lodash";
import $api from "../../utils/axios";
import usePropertyChain from "../../hooks/usePropertyChain";
import useHotel from "../../hooks/userHotel";

const validationSchema = yup.object().shape({
    userType: yup.string().required(),
    commission: yup.string().optional(),
    hotel: yup.array().optional(),
});

const RoleEdit = () => {
    const { chains } = usePropertyChain();
    const { hotels } = useHotel();
    const [user, setUser] = useState({});
    const [initialValues, setInitialValues] = useState({
        userType: "guest",
        commission: "",
        hotel: [],
    });
    const history = useHistory();
    const { userId } = useParams();

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues,
        onSubmit: async (values) => {
            const requestData = {
                userType: values.userType,
                commission: values.commission,
                hotel: values.hotel,
            };
            if (
                ["hotel-manager", "revenue-manager"].includes(values.userType)
            ) {
                requestData.userType = "EMPLOYEE";

                if (values.userType === "hotel-manager")
                    requestData.accessLevel = "REGIONALMANAGER";
                if (values.userType === "revenue-manager")
                    requestData.accessLevel = "GROUPMANAGER";
            }
            try {
                await $api
                    .put(`/user/info/${userId}`, requestData)
                    .then((res) => res.data);
                toast.success("User updated successfully.");
                history.push("/users-all");
            } catch (err) {
                console.log(err);
                toast.error(`An error happened. Error: ${err.message}`);
            }
        },
    });
    const { handleChange, handleSubmit, setFieldValue, values, errors } =
        formik;

    const getRoleType = (user) => {
        if (user.userType === "APP" || user.userType === "ADMIN")
            return user.userType;

        if (user.userType === "EMPLOYEE") {
            if (user.accessLevel === "REGIONALMANAGER") return "hotel-manager";
            if (user.accessLevel === "GROUPMANAGER") return "revenue-manager";
        }

        if (user.accessLevel === "OWNER") return "owner";

        return "guest";
    };

    useEffect(() => {
        if (!userId) return;

        const loadUserData = async () => {
            const res = await $api
                .get(`/user/info/${userId}`)
                .then((res) => res.data);
            setUser(res);
            setInitialValues({
                userType: getRoleType(res),
                commission: res.commission?._id || "",
                hotel: map(res.hotel || [], (r) => r._id),
            });
        };
        loadUserData();
    }, [userId]);

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Typography variant="h4">Role Management</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => history.push("/users-all")}
                    >
                        Users
                    </Link>
                    <Typography color="text.primary">
                        Role Management
                    </Typography>
                </Breadcrumbs>
            </Grid>

            <Container
                sx={{
                    backgroundColor: "white",
                    mt: 2,
                    py: 2,
                    borderRadius: 1,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "none",
                        width: "50%",
                    }}
                >
                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="email"
                            label="Email"
                            value={user.email || ""}
                            disabled
                        />
                    </FormControl>

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                        error={!!errors.userType}
                    >
                        <InputLabel id="demo-simple-select-scope-label">
                            User Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-scope-label"
                            id="demo-simple-select-scope"
                            label="User Type"
                            value={values.userType}
                            onChange={(e) => {
                                if (e.target.value === "hotel-manager")
                                    setFieldValue("commission", undefined);
                                handleChange("userType")(e);
                            }}
                            disabled={values.userType === "APP"}
                        >
                            <MenuItem value={"hotel-manager"}>
                                Hotel Manager
                            </MenuItem>
                            <MenuItem value={"revenue-manager"}>
                                Revenue Manager
                            </MenuItem>
                            <MenuItem value={"owner"}>Owner</MenuItem>
                            <MenuItem value={"ADMIN"}>Admin</MenuItem>
                            {values.userType === "APP" && (
                                <MenuItem value={"APP"}>Customer</MenuItem>
                            )}
                            <MenuItem value={"guest"}>GUEST</MenuItem>
                        </Select>
                        <FormHelperText>{errors.userType}</FormHelperText>
                    </FormControl>

                    {(values.userType === "revenue-manager" ||
                        values.userType === "hotel-manager") && (
                        <FormControl
                            sx={{ m: 1, maxWidth: 500 }}
                            variant="outlined"
                            error={!!errors.commission}
                        >
                            <InputLabel id="demo-simple-select-scope-label">
                                Property Chain
                            </InputLabel>
                            <Select
                                readOnly={values.userType === "hotel-manager"}
                                labelId="demo-simple-select-scope-label"
                                id="demo-simple-select-scope"
                                label="Property Chain"
                                value={values.commission}
                                onChange={handleChange("commission")}
                            >
                                {chains.map((chain) => (
                                    <MenuItem key={chain._id} value={chain._id}>
                                        {chain.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.commission}</FormHelperText>
                        </FormControl>
                    )}

                    {values.userType === "hotel-manager" && (
                        <FormControl
                            sx={{ m: 1, maxWidth: 500 }}
                            variant="outlined"
                            error={!!errors.hotel}
                        >
                            <InputLabel id="demo-simple-select-scope-label">
                                Hotels
                            </InputLabel>
                            <Select
                                multiple
                                labelId="demo-simple-select-scope-label"
                                id="demo-simple-select-scope"
                                label="Hotels"
                                value={values.hotel}
                                onChange={(e) => {
                                    const hotel = hotels.find(
                                        (hotel) =>
                                            hotel._id === e.target.value[0]
                                    );
                                    setFieldValue(
                                        "commission",
                                        hotel?.commission
                                    );
                                    handleChange("hotel")(e);
                                }}
                                renderValue={(selected) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                        }}
                                    >
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={
                                                    hotels.find(
                                                        (hotel) =>
                                                            hotel._id === value
                                                    )?.name
                                                }
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {hotels
                                    .filter(
                                        (hotel) =>
                                            hotel.commission ===
                                                values.commission ||
                                            !values.commission
                                    )
                                    .sort((a, b) =>
                                        a.commission > b.commission ? 1 : -1
                                    )
                                    .map((hotel) => (
                                        <MenuItem
                                            key={hotel._id}
                                            value={hotel._id}
                                        >
                                            {hotel.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText>{errors.hotel}</FormHelperText>
                        </FormControl>
                    )}
                </Box>

                <div style={{ marginTop: "15px", padding: "7px" }}>
                    <Button
                        variant="outlined"
                        sx={{ mr: 1 }}
                        onClick={() => history.goBack()}
                    >
                        Go Back
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </Container>
        </Box>
    );
};

export default memo(RoleEdit);
