import $api from "../../utils/axios";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";

export default function ExchangeRateHistory() {
    const [data, setData] = React.useState([]);
    const [countries, setCountries] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            const resCountries = await $api.get("/vatgst/all");
            setCountries(resCountries.data);
            const res = await $api.get("/logs/exchange-rate-history");
            setData(res.data);
        })();
    }, []);

    const columns = React.useMemo(() => {
        const currencyToCountryMap = {};
        countries.map((country) => 
            currencyToCountryMap[country.currencyFor] = country.countryname,
        );
        const currencyColumns = Object.keys(currencyToCountryMap).map(
            (currency) => ({
                headerName: `${currencyToCountryMap[currency]} (${currency})`,
                field: currency,
                type: "number",
                width: 150,
            })
        );
        return [
            {
                headerName: "Date",
                field: "date",
                type: "string",
                width: 120,
            },
            ...currencyColumns,
        ];
    }, [countries]);

    const rows = React.useMemo(() => data.map(datum => {
        const rates = {}
        countries.map(country => rates[country.currencyFor] = datum.rates[country.currencyFor])
        return {...datum, ...rates}
    }), [countries, data])

    return (
        <Box>
            <h2>
                <strong>Exchange Rate History</strong>
            </h2>
            <Container
                sx={{
                    backgroundColor: "white",
                    my: 2,
                    p: 0,
                    borderRadius: 1,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <DataGrid
                    sx={{ border: 0 }}
                    getRowId={(row) => row._id}
                    autoHeight
                    columns={columns}
                    rows={rows}
                />
            </Container>
        </Box>
    );
}
