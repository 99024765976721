import React, { memo } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Container } from "@mui/system";
import {
    Box,
    Breadcrumbs,
    Link,
    Button,
    Grid,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Chip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import usePropertyChain from "../../hooks/usePropertyChain";
import useHotel from "../../hooks/userHotel";
import $api from "../../utils/axios";

const validationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    mobile: yup.string().required(),
    userType: yup.string().required(),
    commission: yup.string().optional(),
    hotel: yup.array().optional(),
    password: yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, 'The password must be of minimum 6 characters length,\r\nshould contain at least one uppercase & a special character').required("Password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const AddUser = () => {
    const { chains } = usePropertyChain();
    const { hotels } = useHotel();
    const history = useHistory();

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            userType: "APP",
            commission: "",
            hotel: [],
            password: "",
            confirmPassword: "",
        },
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            const requestData = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                mobile: values.mobile,
                userType: values.userType,
                ...(values.commission && { commission: values.commission }),
                hotel: values.hotel,
                password: values.password,
            };
            if (
                ["hotel-manager", "revenue-manager"].includes(values.userType)
            ) {
                requestData.userType = "EMPLOYEE";

                if (values.userType === "hotel-manager")
                    requestData.accessLevel = "REGIONALMANAGER";
                if (values.userType === "revenue-manager")
                    requestData.accessLevel = "GROUPMANAGER";
            }
            try {
                await $api
                    .post(`/user/createByAdmin`, requestData)
                    .then((res) => res.data);
                toast.success("User created successfully.");
                history.push("/users-all");
            } catch (err) {
                console.log(err);
                toast.error(`An error happened. Error: ${err}`);
            }
        },
    });
    const { handleChange, handleSubmit, setFieldValue, values, errors } =
        formik;

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Typography variant="h4">Create User</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => history.push("/users-all")}
                    >
                        Users
                    </Link>
                    <Typography color="text.primary">Create User</Typography>
                </Breadcrumbs>
            </Grid>

            <Container
                sx={{
                    backgroundColor: "white",
                    mt: 2,
                    py: 2,
                    borderRadius: 1,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="email"
                            label="Email"
                            value={values.email || ""}
                            onChange={handleChange("email")}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </FormControl>

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="text"
                            label="First Name"
                            value={values.firstName || ""}
                            onChange={handleChange("firstName")}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                        />
                    </FormControl>

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Last Name"
                            value={values.lastName || ""}
                            onChange={handleChange("lastName")}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                        />
                    </FormControl>

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Phone"
                            value={values.mobile || ""}
                            onChange={handleChange("mobile")}
                            error={!!errors.mobile}
                            helperText={errors.mobile}
                        />
                    </FormControl>

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                        error={!!errors.userType}
                    >
                        <InputLabel id="demo-simple-select-scope-label">
                            User Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-scope-label"
                            id="demo-simple-select-scope"
                            label="User Type"
                            value={values.userType}
                            onChange={(e) => {
                                if (e.target.value === "hotel-manager")
                                    setFieldValue("commission", undefined);
                                handleChange("userType")(e);
                            }}
                        >
                            <MenuItem value={"hotel-manager"}>
                                Hotel Manager
                            </MenuItem>
                            <MenuItem value={"revenue-manager"}>
                                Revenue Manager
                            </MenuItem>
                            <MenuItem value={"ADMIN"}>Admin</MenuItem>
                        </Select>
                        <FormHelperText>{errors.userType}</FormHelperText>
                    </FormControl>

                    {(values.userType === "revenue-manager" ||
                        values.userType === "hotel-manager") && (
                        <FormControl
                            sx={{ m: 1, maxWidth: 500 }}
                            variant="outlined"
                            error={!!errors.commission}
                        >
                            <InputLabel id="demo-simple-select-scope-label">
                                Property Chain
                            </InputLabel>
                            <Select
                                readOnly={values.userType === "hotel-manager"}
                                labelId="demo-simple-select-scope-label"
                                id="demo-simple-select-scope"
                                label="Property Chain"
                                value={values.commission}
                                onChange={handleChange("commission")}
                            >
                                {chains.map((chain) => (
                                    <MenuItem key={chain._id} value={chain._id}>
                                        {chain.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.commission}</FormHelperText>
                        </FormControl>
                    )}

                    {values.userType === "hotel-manager" && (
                        <FormControl
                            sx={{ m: 1, maxWidth: 500 }}
                            variant="outlined"
                            error={!!errors.hotel}
                        >
                            <InputLabel id="demo-simple-select-scope-label">
                                Hotels
                            </InputLabel>
                            <Select
                                multiple
                                labelId="demo-simple-select-scope-label"
                                id="demo-simple-select-scope"
                                label="Hotels"
                                value={values.hotel}
                                onChange={(e) => {
                                    const hotel = hotels.find(
                                        (hotel) =>
                                            hotel._id === e.target.value[0]
                                    );
                                    setFieldValue(
                                        "commission",
                                        hotel?.commission
                                    );
                                    handleChange("hotel")(e);
                                }}
                                renderValue={(selected) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                        }}
                                    >
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={
                                                    hotels.find(
                                                        (hotel) =>
                                                            hotel._id === value
                                                    )?.name
                                                }
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {hotels
                                    .filter(
                                        (hotel) =>
                                            hotel.commission ===
                                                values.commission ||
                                            !values.commission
                                    )
                                    .sort((a, b) =>
                                        a.commission > b.commission ? 1 : -1
                                    )
                                    .map((hotel) => (
                                        <MenuItem
                                            key={hotel._id}
                                            value={hotel._id}
                                        >
                                            {hotel.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText>{errors.hotel}</FormHelperText>
                        </FormControl>
                    )}

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="password"
                            label="Password"
                            value={values.password || ""}
                            onChange={handleChange("password")}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                    </FormControl>

                    <FormControl
                        sx={{ m: 1, maxWidth: 500 }}
                        variant="outlined"
                    >
                        <TextField
                            variant="outlined"
                            type="password"
                            label="Confirm Password"
                            value={values.confirmPassword || ""}
                            onChange={handleChange("confirmPassword")}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />
                    </FormControl>
                </Box>
                <div style={{ marginTop: "15px", padding: "7px" }}>
                    <Button
                        variant="outlined"
                        sx={{ mr: 1 }}
                        onClick={() => history.goBack()}
                    >
                        Go Back
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </Container>
        </Box>
    );
};

export default memo(AddUser);
