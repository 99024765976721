import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import $api from '../../utils/axios';
import { map, filter, debounce } from 'lodash';

export default function HotelAutoComplete(props) {
  const { value: initialValues, onChange } = props;
  const [value, setValue] = React.useState(initialValues || []);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      debounce((query) => {
        let options = [];
        $api.post('/hotel/query', { query })
          .then((res) => {
            options = filter(res.data.data, r => r._id && !map(value, '_id').includes(r._id));
            setOptions(options);
          })
      }, 400),
    [value],
  );

  React.useEffect(() => {
    if (inputValue === '') {
      return undefined;
    }
    if (inputValue && inputValue.length > 2) {
      fetch(inputValue);
    }
  }, [inputValue, fetch]);



  return (
    <Autocomplete
      id="hotel-auto-complete"
      sx={{ minWidth: 300 }}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.name
      }}
      multiple
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No hotels"
      onChange={(event, newValue) => {
        const newOptions = filter(options, r => !map(newValue, '_id').includes(r._id));
        setOptions(newOptions);
        setValue(newValue);
        onChange && onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Hotels" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography variant="body" color="text.secondary">
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}
