import React, { useEffect } from 'react';
import $api from '../utils/axios'
import { map } from 'lodash';

export default function usePropertyChain() {
  const [chains, setChains] = React.useState([])

  useEffect(() => {
    (async () => {
      const res = await $api.post('/admin/commission/list')
      setChains(map(res.data, r => ({ _id: r._id, name: r.clientTypeName })));
    })();
  }, []);

  return { chains };
}
