import React, { memo, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Container } from "@mui/system";
import {
  Box,
  Breadcrumbs,
  Link,
  Button,
  Grid,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import $api from "../../utils/axios";

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  mobile: yup.string().required(),
  dob: yup.string().optional(),
});

const UserForm = () => {
  const [user, setUser] = useState({});
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    dob: "",
  });
  const history = useHistory();
  const { userId } = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      const requestData = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: values.mobile,
        dob: values.dob,
      };
      try {
        await $api
          .put(`/user/info/${userId}`, requestData)
          .then((res) => res.data);
        toast.success("User updated successfully.");
        history.push("/users-all");
      } catch (err) {
        console.log(err);
        toast.error(`An error happened. Error: ${err.message}`);
      }
    },
  });
  const { handleChange, handleSubmit, setFieldValue, values, errors } = formik;

  const getRoleType = () => {
    if (!user) return "";
    if (user.userType === "ADMIN") return "ADMIN";
    if (user.userType === "APP") return "CUSTOMER";
    if (user.userType === "EMPLOYEE") {
      if (user.accessLevel === "REGIONALMANAGER") return "Hotel Manager";
      if (user.accessLevel === "GROUPMANAGER") return "Revenue Manager";
      if (user.accessLevel === "OWNER") return "Hotel Owner";
    }
    return "GUEST";
  };

  useEffect(() => {
    if (!userId) return;

    const loadUserData = async () => {
      const res = await $api
        .get(`/user/info/${userId}`)
        .then((res) => res.data);
      setUser(res);
      setInitialValues({
        firstName: res.firstName,
        lastName: res.lastName,
        mobile: res.mobile || res.contactNo,
        dob: res.dob || "",
      });
    };
    loadUserData();
  }, [userId]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="h4">Edit User</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" onClick={() => history.push("/users-all")}>
            Users
          </Link>
          <Typography color="text.primary">Edit User</Typography>
        </Breadcrumbs>
      </Grid>

      <Container
        sx={{
          backgroundColor: "white",
          mt: 2,
          py: 2,
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "none",
              width: "50%",
            }}
          >
            <FormControl sx={{ m: 1, maxWidth: 500 }} variant="outlined">
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                value={user.email || ""}
                disabled
              />
            </FormControl>

            <FormControl sx={{ m: 1, maxWidth: 500 }} variant="outlined">
              <TextField
                variant="outlined"
                type="text"
                label="First Name"
                value={values.firstName || ""}
                onChange={handleChange("firstName")}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </FormControl>

            <FormControl sx={{ m: 1, maxWidth: 500 }} variant="outlined">
              <TextField
                variant="outlined"
                type="text"
                label="Last Name"
                value={values.lastName || ""}
                onChange={handleChange("lastName")}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </FormControl>

            <FormControl sx={{ m: 1, maxWidth: 500 }} variant="outlined">
              <TextField
                variant="outlined"
                type="text"
                label="Phone"
                value={values.mobile || ""}
                onChange={handleChange("mobile")}
                error={!!errors.mobile}
                helperText={errors.mobile}
              />
            </FormControl>

            <FormControl sx={{ m: 1, maxWidth: 500 }} variant="outlined">
              <DatePicker
                autoFocus
                label="Date of Birth"
                value={moment(values.dob)}
                onChange={(val) =>
                  setFieldValue("dob", val.format("YYYY-MM-DD"))
                }
                inputFormat="DD/MM/YYYY"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    sx={{ width: "100%", maxWidth: 500 }}
                    error={!!errors.dob}
                    helperText={errors.dob}
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" sx={{ margin: "20px 10px 20px 20px" }}>
              Role Type: {getRoleType()}
            </Typography>
            {user.userType === "EMPLOYEE" &&
              user.accessLevel === "GROUPMANAGER" && (
                <Typography
                  variant="body1"
                  sx={{ margin: "20px 10px 20px 20px" }}
                >
                  Chain: {user?.commission?.clientTypeName}
                </Typography>
              )}
            {user.userType === "EMPLOYEE" &&
              user.accessLevel === "REGIONALMANAGER" && (
                <Typography
                  variant="body1"
                  sx={{ margin: "20px 10px 20px 20px" }}
                >
                  Hotels:
                  {user?.hotel?.map((a, index) => (
                    <p key={index}>{a.name}</p>
                  ))}
                </Typography>
              )}

            <Typography variant="body1" sx={{ margin: "20px 10px 20px 20px" }}>
              Created At: {moment(user.createdAt).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="body1" sx={{ margin: "20px 10px 20px 20px" }}>
              Updated At: {moment(user.updatedAt).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="body1" sx={{ margin: "20px 10px 20px 20px" }}>
              Last logged in:{" "}
              {user.lastLoggedIn
                ? moment(user.lastLoggedIn).format("YYYY-MM-DD")
                : "N/A"}
            </Typography>
            <Typography variant="body1" sx={{ margin: "20px 10px 20px 20px" }}>
              Operating System:{" "}
              {user.signupDevice
                ? user.signupDevice
                : "N/A"}
            </Typography>
          </Box>
        </Box>

        <div style={{ marginTop: "15px", padding: "7px" }}>
          <Button
            variant="outlined"
            sx={{ mr: 1 }}
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Container>
    </Box>
  );
};

export default memo(UserForm);
