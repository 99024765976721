import React, { Component } from "react";
import axios from 'axios';
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'FULL NAME',
        selector: row => row.fullName,
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: row => row.email,
        sortable: true,
        grow: 2,
    },
    {
        name: 'MOBILE',
        selector: row => row.mobile,
        sortable: true,
    },
    {
        name: 'BOOKINGS',
        selector: row => row.bookings,
        sortable: true,
    },
    {
        name: 'COMPLETED STAYS',
        selector: row => row.stays,
        sortable: true,
    },
    {
        name: 'ACCOUNT TYPE',
        selector: row => row.provider,
        sortable: true,
    },
    {
        name: 'RECORDS',
        selector: row => row.action,
    },
];

export default class Customers extends Component {
    state = {
        loading: true,
        records: [],
    }
    componentDidMount() {
        this.fetchCustomer();
    }

    async fetchCustomer() {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/user/customer`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                if (response.status === 200) {
                    let array = [];
                    Object.values(response.data).forEach(element => {
                        let countBookings = 0;
                        let countStay = 0;
                        element.forEach(data => {
                            if(data.reserve.status === 'Booked') {
                                countBookings = countBookings + +1;  
                            }
                            if(data.reserve.status === 'Checked in') {
                                countStay = countStay + +1;  
                            }
                        })
                        array.push({
                            _id: element._id,
                            fullName: element[0].firstName+" "+element[0].lastName,
                            email: element[0].email,
                            mobile: element[0].mobile,
                            bookings: countBookings,
                            stays: countStay,
                            provider: element[0].provider !== 'Guest' ? 'Customer' : 'Guest',
                            action: <a href={`/customerRecords/${element[0]._id}`} className="btn btn-embossed btn-primary" style={{ width: 100 }}>View</a>,
                        })
                    });
                    this.setState({
                        records: array,
                        loading: false
                    })
                }
            })
    }


    render() {
        const { history } = this.props
        const { loading, records } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage </strong>Customers</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                                <li>
                                    <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                                </li>
                            <li className="active">Customers</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <h3><strong>Customers</strong> </h3>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Name, Email, Phone" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}