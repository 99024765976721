import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import $api from '../../utils/axios';
//import { map, filter, debounce } from 'lodash';
import { map, filter } from 'lodash';
export default function ReservationHotelAutoComplete(props) {
  const { value: initialValues, onChange } = props;
  const [value, setValue] = React.useState(initialValues || []);
  const [inputValue, setInputValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);


  React.useEffect(() => {
    (async () => {
      setInputValue(props.propertyId);

      const fetch = async () => {
        try {
          // Make an asynchronous request, for example using the fetch API
          let options = [];
          $api.post('/hotel/querybyproperty', { inputValue })
            .then((res) => {
              options = filter(res.data.data, r => r._id && !map(value, '_id').includes(r._id));
              setOptions(options);
            })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      if(inputValue !=="undefined" && inputValue !==undefined && inputValue.length > 0) {
      fetch(inputValue);
      }
      /*let options = [];
      $api.post('/hotel/querybyproperty', { inputValue })
        .then((res) => {
          options = filter(res.data.data, r => r._id && !map(value, '_id').includes(r._id));
          setOptions(options);
        })*/
      //const res = await $api.get(`/archives/${resHotel.data[0]._id}`);
      //setOptions(resHotel.data);
      /*setTabs(res.data);
      setRows(res.data[0].availabilities);
      setCurrentTab(res.data[0]._id);
      setHotelname(resHotel.data[0].hotelName);*/
    })();
  }, [props, inputValue, value]);

  /*const fetch = React.useMemo(
    () =>
      debounce((query) => {
        let options = [];
        console.log("hotellistttttttttttttttttttttttttttttttt222222222222222222222222");
        console.log(query);
        $api.post('/hotel/querybyproperty', { query })
          .then((res) => {
            options = filter(res.data.data, r => r._id && !map(value, '_id').includes(r._id));
            setOptions(options);
          })
      }, 400),
    [value],
  );*/

  /*React.useEffect(() => {
    console.log("hotellistttttttttttttttttttttttttttttttt1111111111111111");
    console.log(inputValue);
    if (inputValue.length === 0) {
      return undefined;
    }
    if (inputValue && inputValue.length > 0) {
      fetch(inputValue);
    }
  }, [inputValue, fetch]);*/



  return (
    <Autocomplete
      id="hotel-auto-complete"
      sx={{ minWidth: 300 }}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.name
      }}
      multiple
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No hotels"
      onChange={(event, newValue) => {
        const newOptions = filter(options, r => !map(newValue, '_id').includes(r._id));
        setOptions(newOptions);
        setValue(newValue);
        onChange && onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Hotels" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography variant="body" color="text.secondary">
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}
