import React from "react";
import {
  Autocomplete,
  Box,
  Grid,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { map, filter } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import ReservationHotelAutoComplete from "../../components/Hotel/ReservationHotelAutoComplete";

const FilterForm = ({ onSubmit, chains }) => {
  const filterFormFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      billing: "all",
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { handleChange, handleSubmit, setFieldValue, values, errors } =
    filterFormFormik;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      pt={1}
    >
      <FormControl sx={{ m: 1, minWidth: "25ch", flex: 1 }} variant="outlined">
        <TextField
          variant="outlined"
          type="text"
          label="Search by Name, Property..."
          value={values.search || ""}
          onChange={handleChange("search")}
          error={!!errors.search}
          helperText={errors.search}
          autoFocus
        />
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: "25ch" }} variant="outlined">
        <Autocomplete
          id="demo-simple-select-scope"
          label="Property Chains"
          getOptionLabel={(option) => {
            return typeof option === "string" ? option : option.name;
          }}
          filterOptions={(x) => x}
          value={values.propertyChain}
          onChange={(event, newValue) => {
            setFieldValue(
              "propertyChain",
              newValue?.map((v) => v._id)
            );
          }}
          autoComplete
          includeInputInList
          filterSelectedOptions
          options={filter(
            chains,
            (chain) => !map(values.propertyChain, "_id").includes(chain._id)
          )}
          multiple
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {values.propertyChain.map((value) => (
                <Chip key={value._id} label={value.name} />
              ))}
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Property Chains" fullWidth />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Typography variant="body" color="text.secondary">
                  {option.name}
                </Typography>
              </li>
            );
          }}
        />
        <FormHelperText>{errors.propertyChain}</FormHelperText>
      </FormControl>

      <FormControl sx={{ m: 1 }} variant="outlined" error={!!errors.hotel}>
        <ReservationHotelAutoComplete
          propertyId={values.propertyChain}
          value={values.hotel}
          onChange={(value) =>
            setFieldValue(
              "hotel",
              value?.map((v) => v._id)
            )
          }
        />
        <FormHelperText>{errors.hotel}</FormHelperText>
      </FormControl>

      <FormControl
        sx={{ m: 1, width: "22ch" }}
        variant="outlined"
        error={!!errors.billing}
      >
        <InputLabel id="demo-simple-select-scope-label">
          TimeFlyz Deduction
        </InputLabel>
        <Select
          label="TimeFlyz Deduction"
          value={values.billing}
          onChange={handleChange("billing")}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="yes">Yes</MenuItem>
          <MenuItem value="no">No</MenuItem>
          <MenuItem value="excluded">Excluded</MenuItem>
        </Select>
        <FormHelperText>{errors.billing}</FormHelperText>
      </FormControl>

      <Button
        variant="contained"
        onClick={handleSubmit}
        style={{ height: 53.53, marginTop: -4 }}
      >
        <SearchIcon />
      </Button>
    </Grid>
  );
};

export default FilterForm;
