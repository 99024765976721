import $api from '../../utils/axios'
import { capitalize, get } from 'lodash';
import { useHistory } from "react-router-dom";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import { Container } from '@mui/system';
import moment from 'moment';
import { Delete } from '@mui/icons-material';

export default function BasicTable() {
  let history = useHistory();

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await $api.get('/coupons');
      setRows(res.data);
    })();

  }, []);

  const editCoupon = React.useCallback(
    (id) => () => {
      history.push(`/coupons/${id}`);
    },
    [history],
  );

  const deleteCoupon = React.useCallback((id) => async () => {
    await $api.delete(`/coupons/${id}`);
    setRows((prevRows) =>
      prevRows.filter((row) => row._id !== id)
    );
  }, []);

  const columns = React.useMemo(
    () => [
      { headerName: 'Code', field: 'code', type: 'string' },
      {
        headerName: 'Value', field: 'value', type: 'number',
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return `${params.value} %`;
        },
      },
      {
        headerName: 'Start Date', field: 'startDate', type: 'date', width: 150,
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return moment(params.value).format('DD/MM/YYYY');
        },
      },
      {
        headerName: 'End Date', field: 'endDate', type: 'date', width: 150,
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return moment(params.value).format('DD/MM/YYYY');
        },
      },
      {
        headerName: 'Discount Applied To', field: 'appliedTo', type: 'string', width: 120,
        valueFormatter: (params) => {
          if (!params.value) {
            return '';
          }
          return capitalize(params.value);
        },
      },
      {
        headerName: 'Scope', field: 'scope', type: 'string', width: 120,
        valueFormatter: (params) => {
          return capitalize(params.value);
        },
      },
      {
        headerName: 'Created By', field: 'createdBy', type: 'string', width: 120,
        valueFormatter: (params) => {
          return get(params, 'value.fullName', '');
        },
      },
      {
        headerName: 'Created At', field: 'createdAt', type: 'date', width: 120,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={editCoupon(params.id)}
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Remove"
            onClick={deleteCoupon(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [
      editCoupon,
      deleteCoupon
    ],
  );

  const createCoupon = () => {
    history.push('/coupons/create', { replace: true });
  };

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="h4" color="initial">Coupons</Typography>
        <Button variant="contained" color='secondary' startIcon={<Add />} onClick={createCoupon}>New Coupon</Button>
      </Grid>
      {/* <DataGrid rows={rows} columns={columns} /> */}
      <Container sx={{
        backgroundColor: 'white',
        my: 2,
        p: 0,
        borderRadius: 1,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
      }}>
        <DataGrid sx={{ border: 0 }} getRowId={(row) => row._id} autoHeight columns={columns} rows={rows} />
      </Container>
    </Box >
  );
}