import $api from "../../utils/axios";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import moment from "moment";

export default function ExchangeRateGain() {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await $api.get("/logs/exchange-rate-gain");
      setRows(res.data.map(row => ({...row, status: row.logType === "ExchangeRateGainSuccess" ? "Success" : "Fail"})));
    })();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => [
    {
      headerName: "Start",
      field: "start",
      type: "date",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM HH:mm");
      },
    },
    {
      headerName: "End",
      field: "end",
      type: "date",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM HH:mm");
      },
    },
    {
        headerName: "Status",
        field: "status",
        type: "string",
        width: 120,
      },
  ]);

  return (
    <Box>
      <h2>
        <strong>Logs</strong> / OpenExchange Rate
      </h2>
      <Container
        sx={{
          backgroundColor: "white",
          my: 2,
          p: 0,
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <DataGrid
          sx={{ border: 0 }}
          getRowId={(row) => row._id}
          autoHeight
          columns={columns}
          rows={rows}
        />
      </Container>
    </Box>
  );
}
