import React, {Component} from 'react';
import axios from 'axios';
import DataTable from "../../components/DataTable";
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

const columns = [
    {
        name: 'FULL NAME',
        selector: row => row.fullName,
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: row => row.email,
        sortable: true,
        grow: 2
    },
    {
        name: 'PHONE',
        selector: row => row.contactNo,
        sortable: true,
    },
    {
        name: 'ACCOUNT TYPE',
        selector: row => row.provider,
        sortable: true,
    },
    {
        name: 'PROPERTY CHAIN',
        selector: row => row.hotelType,
        sortable: true,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        grow: 2,
        wrap: true,
    },
];

const viewAllPropertiesColumn = [
    {
        name: 'PROPERTY',
        selector: row => row.propertyNameWithCheckbox,
        sortable: true,
    },
];
export default class GroupRegionalManager extends Component{
    state = {
        records: [],
        loading: false,
        modalManagerStatus: false,
        managerData: [],
        fName: '',
        lName: '',
        email: '',
        fNameError: '',
        lNameError: '',
        emailError: '',
        alertShow: false,
        alertBoxData: [],
        alertBoxTitle: '',
        regionalManagerSelectedPropertyError: '',
        modalRegionalManagerPropertiesList: false,
        properties: [],
        regionalManagerSelectedProperty: [],
        viewAllPropertyLoading: false,
        hotelType: [],
    }
    componentDidMount(){
        this.fetchManagers();
    }
    fetchManagers = async() => {
        this.setState({loading: true});
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/user/employeeList`,{
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if(response.status === 200){
                const array = [];
                response.data.forEach(element => {
                    if(element.accessLevel === 'GROUPMANAGER' || element.accessLevel === 'REGIONALMANAGER'){
                        array.push({
                            fullName: element.fullName ? element.fullName : `${element.firstName} ${element.lastName} `,
                            email: element.email,
                            contactNo: element.mobile,
                            provider: element.accessLevel ? element.accessLevel === 'GROUPMANAGER' ? 'Revenue Manager' : 'Hotel Manager' : null,
                            hotelType: (element && element.commission && element.commission.clientTypeName) || '',
                            action: <><a href="#/" className="btn btn-embossed btn-primary" style={{ width: 100 }} onClick={() => this.showUpdateModal(element)}>Edit</a>
                            <a href="#/" className="btn btn-embossed btn-danger" style={{ width: 100 }} onClick={() => this.deleteManager(element)}>Delete</a></>
                        })
                    }
                });
                this.setState({
                    records: array,
                    loading: false
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    onChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    showUpdateModal(managerData){
        this.setState({
            modalManagerStatus: true,
            fName: managerData.firstName ? managerData.firstName : this.splitFullName(managerData.fullName)[0],
            lName: managerData.lastName ? managerData.lastName : this.splitFullName(managerData.fullName)[1],
            email: managerData.email,
            hotelTypeName: managerData.commission.clientTypeName,
            managerData,
        },() => {
            this.showProperties(managerData)
        })
    }
    splitFullName(name, index){
        let splitName = name.split(" ");
        return splitName;
    }
    closeManagerStatus = (e) => {
        e.preventDefault();
        this.setState({
            modalManagerStatus: false,
            fName: '',
            lName: '',
            email: '',
            managerData: [],
            regionalManagerSelectedProperty: [],
        })
    }

    deleteManager = (array) => {
        this.setState({
            alertShow: true,
            alertBoxData: array,
            alertBoxTitle: 'Please confirm you wish to delete this manager ?',
        })
    } 

    onCancel(){
        this.setState({
            alertShow: false,
            alertBoxData: [],
            alertBoxTitle: '',
        })
    }
    confirmFunc = async() => {
        const { alertBoxData } = this.state;
        let token = await localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/user/removeManager/${alertBoxData._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.data === 'OK') {
                toast.success('Manager Deleted Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.location.reload();
            } else {
                toast.error('Something went wrong, Please try again...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        })
        .catch(error => {
            console.log(error);
        })
    }
    

    showAllProperties(array) {
        this.setState({
            modalManagerStatus: false,
            modalRegionalManagerPropertiesList: true,
            regionalManagerSelectedProperty: []
        }, () => {
            this.showProperties(array);
        })
    }

    closeShowAllProperties = () => {
        this.setState({
            modalManagerStatus: true,
            modalRegionalManagerPropertiesList: false,
        })
    }

    showProperties = async (managerArray) => {
        this.setState({ viewPropertyLoading: true, viewAllPropertyLoading: true });
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            const array = [];
            this.setState({
                regionalManagerSelectedProperty: managerArray.hotel
            })
            let sortArray = response.data.sort((a,b) => {
                return a.name.localeCompare(b.name);
            });
            sortArray.forEach(element => {
                if (element.commission && managerArray.commission._id === element.commission) {
                    array.push({
                        propertyId: element._id,
                        name: element.name,
                        propertyName: <a href={`/editHotel/${element._id}`}>{element.name}</a>,
                        propertyNameWithCheckbox:
                        <div className="text-center">
                            <div className="input-group">
                                <div className="icheck-inline">
                                    <label>
                                        <Checkbox value={element._id} defaultChecked={this.state.regionalManagerSelectedProperty.includes(element._id)} onChange={this.regionalManagerProperty} data-checkbox="icheckbox_square-blue" />&nbsp; &nbsp;{element.name}
                                    </label>
                                </div>
                            </div>
                        </div>
                    })
                }
            });
            this.setState({
                properties: array,
                viewPropertyLoading: false,
                viewAllPropertyLoading: false,
            })
        })
        .catch(error => {
            console.log(error);
        })
    }
    

    regionalManagerProperty = (e) => {
        const { value } = e.target;
        let regionalManagerSelectedProperty = this.state.regionalManagerSelectedProperty;

        if (!regionalManagerSelectedProperty.includes(value)) {
            regionalManagerSelectedProperty.push(value)
        } else {
            var index = regionalManagerSelectedProperty.indexOf(value)
            if (index !== -1) {
                regionalManagerSelectedProperty.splice(index, 1);
            }
        }

        this.setState({
            regionalManagerSelectedProperty
        })
    }
    
    validateManager = () => {
        this.setState({
            fNameError: '',
            lNameError: '',
            emailError: '',
            regionalManagerSelectedPropertyError: ''
        })
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

        let fNameError = '';
        let lNameError = '';
        let emailError = '';
        let regionalManagerSelectedPropertyError = '';

        if (this.state.fName === '') {
            fNameError = "Please Enter First Name"
        }
        if (fNameError) {
            this.setState({ fNameError })
            return false
        }

        if (this.state.lName === '') {
            lNameError = "Please Enter Last Name"
        }
        if (lNameError) {
            this.setState({ lNameError })
            return false
        }

        if (this.state.email === '') {
            emailError = "Please Enter Email"
        } else if (!regex.test(this.state.email.trim())) {
            emailError = "Please enter a valid Email Address"
        }
        if (emailError) {
            this.setState({ emailError })
            return false
        }

        /*if (this.state.managerType === '') {
            managerTypeError = "Please Select Manager Type"
        }
        if (managerTypeError) {
            this.setState({ managerTypeError })
            return false
        }*/

        if (this.state.managerData.accessLevel === 'REGIONALMANAGER' && this.state.regionalManagerSelectedProperty.length < 1) {
            regionalManagerSelectedPropertyError = "Please Select Hotels For Manager"
        }
        if (regionalManagerSelectedPropertyError) {
            this.setState({ regionalManagerSelectedPropertyError })
            return false
        }
        return true
    }

    updateManager = async(e) => {
        e.preventDefault();
        const validate = this.validateManager();
        if(validate){
            const {fName, lName, email, regionalManagerSelectedProperty, managerData} = this.state;
            let payload = null;
            if(managerData.accessLevel === 'REGIONALMANAGER'){
                payload = {
                    "_id" : managerData._id,
                    "firstName" : fName,
                    "lastName": lName,
                    "email": email,
                    "hotel": regionalManagerSelectedProperty
                }
            } else {
                payload = {
                    "_id" : managerData._id,
                    "firstName" : fName,
                    "lastName": lName,
                    "email": email,
                }
            }
            let token = await localStorage.getItem('token');
            await axios.put(`${process.env.REACT_APP_API_URL}/user/UpdateManager`, payload , {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then(response => {
                if (response.data === 'OK') {
                    toast.success('Manager Updated Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    window.location.reload();
                } else {
                    toast.error('Something went wrong, Please try again...', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            })
            .catch(error => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(error);
            })
        }
    }

    render(){
        const {records, loading, modalManagerStatus, fName, lName, email, fNameError, lNameError, emailError, managerData, alertShow, alertBoxTitle, regionalManagerSelectedProperty, regionalManagerSelectedPropertyError, properties, modalRegionalManagerPropertiesList, viewAllPropertyLoading} = this.state;
        return(
            <div>
                <SweetAlert
                    show={alertShow}
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="danger"
                    title={alertBoxTitle}
                    onConfirm={() => this.confirmFunc()}
                    onCancel={() => this.onCancel()}
                    focusCancelBtn
                />
                <div className="header">
                    <h2><strong>Manage </strong>Revenue / Hotel Managers</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li><a href="/dashboard">TimeFlyz</a>
                            </li>
                            <li className="active">Revenue / Hotel Managers</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row" style={{ padding: 10 }}>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>Revenue / Hotel Managers</strong> </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Property Chain" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={modalManagerStatus ? "modal fade in" : "modal fade"} aria-hidden="true" style={modalManagerStatus ? { display: 'block' } : { display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={this.updateManager}>
                                <div className="modal-header" style={{ background: '#ef4a23', color: '#fff' }}>
                                    <button type="button" className="close" aria-hidden="true" onClick={this.closeManagerStatus}><i className="fa fa-times" style={{ fontSize: 18 }} /></button>
                                    <h4 className="modal-title"><strong>Edit</strong> Manager</h4>
                                </div>
                                <div className="modal-body" style={{minHeight: 150}}>
                                    <label className="control-label col-md-3">First Name</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter First Name" name="fName" onChange={this.onChange} defaultValue={fName} />
                                            {fNameError ? (<div className="text-danger">{fNameError}</div>) : null}
                                        </div>
                                    </div>
                                    <label className="control-label col-md-3">Last Name</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Last Name" name="lName" onChange={this.onChange} defaultValue={lName} />
                                            {lNameError ? (<div className="text-danger">{lNameError}</div>) : null}
                                        </div>
                                    </div>
                                    <label className="control-label col-md-3">Email</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Enter Email" name="email" onChange={this.onChange} defaultValue={email} />
                                            {emailError ? (<div className="text-danger">{emailError}</div>) : null}
                                        </div>
                                    </div>
                                    <label className="control-label col-md-3">Manager Type</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <span>{managerData.accessLevel === 'GROUPMANAGER' ? 'Revenue Manager' : 'Hotel Manager'}</span>
                                        </div>
                                    </div>
                                    
                                    <label className="control-label col-md-3">Hotel Type</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <span>{this.state.hotelTypeName}</span>
                                        </div>
                                    </div>
                                    {
                                        managerData.accessLevel === 'REGIONALMANAGER' ?
                                            <>
                                                <label className="control-label col-md-3">Hotels List</label>
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <a href="#/" rel="noreferrer" className="control-label btn btn-default btn-embossed bnt-square" style={{ textAlign: 'center' }} onClick={() => this.showAllProperties(managerData)}>Show Hotel List</a>
                                                        {regionalManagerSelectedPropertyError ? (<div className="text-danger">{regionalManagerSelectedPropertyError}</div>) : null}
                                                    </div>
                                                    {
                                                        regionalManagerSelectedProperty.length > 0 ?
                                                            <ul style={{ minHeight: 120, overflowY: 'scroll' }}>
                                                                {
                                                                    properties.map((data, index) => {
                                                                        return (
                                                                            regionalManagerSelectedProperty.includes(data.propertyId) ?
                                                                                <li key={index}>{data.name}</li>
                                                                                :
                                                                                null
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="reset" className="btn btn-primary btn-embossed bnt-square" onClick={this.closeManagerStatus}> Cancel</button>
                                    <button type="submit" className="btn btn-danger btn-embossed bnt-square"> Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={modalRegionalManagerPropertiesList ? "modal fade in" : "modal fade"} aria-hidden="true" style={modalRegionalManagerPropertiesList ? { display: 'block' } : { display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ background: '#ef4a23', color: '#fff' }}>
                                <button type="button" className="close" aria-hidden="true" onClick={this.closeShowAllProperties}><i className="fa fa-times" style={{ fontSize: 18 }} /></button>
                                <h4 className="modal-title"><strong>Select</strong> hotels</h4>
                            </div>
                            <div className="modal-body">
                                <div className="panel-content pagination2 table-responsive" style={{ height: 400 }}>
                                   <DataTable columns={viewAllPropertiesColumn} data={properties} loading={viewAllPropertyLoading} filterDefaultText="Filter By Property Chain" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="reset" className="btn btn-primary btn-embossed bnt-square" onClick={this.closeShowAllProperties}> Cancel</button>
                                <button type="button" className="btn btn-danger btn-embossed bnt-square" onClick={this.closeShowAllProperties}> Add Selected Hotels</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}