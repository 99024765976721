import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import history from "./services/history";
import Routes from "./routes";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { deepOrange, grey } from '@mui/material/colors';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  palette: {
    primary: {
      main: deepOrange[600],
    },
    secondary: {
      main: grey[800],
    },
  },
  typography: {
    fontSize: 20
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router history={history} >
          <ToastContainer />
          <Routes />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
