import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";

const ExportExcel = ({ data }) => {
  const fileName = `Reservations-${moment().format("YYYY-MM-DD")}`;
  const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`;
  const fileExtension = `.xlsx`;

  /*const getRoleType = (row) => {
    if (row.userType === "ADMIN") return "ADMIN";
    if (row.userType === "APP") return "CUSTOMER";
    if (row.userType === "EMPLOYEE") {
      if (row.accessLevel !== "REGIONALMANAGER") return "Hotel Manager";
      if (row.accessLevel !== "GROUPMANAGER") return "Revenue Manager";
      if (row.accessLevel !== "OWNER") return "Hotel Owner";
    }
    return "GUEST";
  };

  const getChainName = (row) => {
    if (!row.commission || row.userType !== "EMPLOYEE") return "";
    return row.commission.clientTypeName;
  };

  const getHotelName = (row) => {
    if (!row.hotel || row.userType !== "EMPLOYEE") return "";
    return row.hotel?.map((a) => a.name).join(", ");
  };*/

  const exportToExcel = async () => {
    const excelData = data.map((item) => {
      return {
        "RESERVATION NUMBER": item.bookingId,
        "SURNAME": item.lastName,
        "PROPERTY": item.name,
        "LENGTH OF STAY": item.duration,
        "DATE & TIME OF ARRIVAL": item.reservationStart,
        "STATUS": item.status,
        "TOTAL RATE": item.amount,
      };
    });
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{ marginRight: 2 }}
      onClick={exportToExcel}
    >
      <DownloadIcon />
      Export Excel
    </Button>
  );
};

export default ExportExcel;
