import * as React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const ConfirmModal = ({ open, onClose, onConfirm }) => {
    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose}>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ErrorOutlineIcon
                        color="primary"
                        sx={{ fontSize: 50, marginBottom: 1 }}
                    />
                    <p>
                        Email has already gone out. Would you like to resend
                        again?
                    </p>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", columnGap: 1 }}>
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                    <Button onClick={onConfirm} variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ConfirmModal;
