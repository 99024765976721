import React, { Component } from 'react';
import moment from 'moment';
const notifications = [
    {
        title: "New Property Added",
        time: Date.now()
    }
];
export default class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            notifyShow:false,
            showDropdownMyAccount: false
        }
        this.wrapperRef = React.createRef();
        this.wrapperMyAccountRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                notifyShow: false
            })
        }

        if (this.wrapperMyAccountRef && !this.wrapperMyAccountRef.current.contains(event.target)) {
            this.setState({
                showDropdownMyAccount: false
            })
        }
    }
    
    showNotification = () => {
        const {notifyShow} = this.state;
        if(notifyShow){
            this.setState({
                notifyShow: false
            })
        }else{
            this.setState({
                notifyShow: true
            })
        }
    }

    toggleSidebar(){
        let toggle;
        if(this.props.toggleSidebar){
            toggle = false;
        }else{
            toggle = true;
        }
        this.props.changeSidebar(toggle);
    }

    showDropdown = () => {
        this.setState({
            showDropdownMyAccount: true
        })
    }

    logout(){
        localStorage.removeItem('token');
    }

    render() {
        const {notifyShow, showDropdownMyAccount} = this.state;
        return (
            <div className="topbar">
                <div className="header-left">
                    <div className="topnav">
                        <a className="menutoggle" href={() => null} onClick={() => this.toggleSidebar()} data-toggle="sidebar-collapsed"><span className="menu__handle"><span>Menu</span></span></a>
                    </div>
                </div>

                <div className="header-right">
                    <ul className="header-menu nav navbar-nav">
                        {/* BEGIN NOTIFICATION DROPDOWN */}
                        <li className="dropdown" id="notifications-header">
                            <a href={() => null} data-toggle="dropdown" data-hover="dropdown" data-close-others="true" onClick={this.showNotification}>
                                <i className="icon-bell" />
                                <span className="badge badge-danger badge-header">{notifications.length}</span>
                            </a>
                            <ul className={notifyShow ? "dropdown-menu show" : "dropdown-menu"} ref={this.wrapperRef}>
                                <li className="dropdown-header clearfix">
                                    <p className="pull-left">{notifications.length} Pending Notification</p>
                                </li>
                                <li>
                                    <ul className="dropdown-menu-list withScroll" data-height={220}>
                                        {
                                            notifications.map((data,index) => {
                                                return(
                                                    <li key={index}>
                                                        <a href={() => null}>
                                                            {data.title}
                                                            <span className="dropdown-time">{moment(data.time).utc().format("MMM D YYYY")}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        {/* END NOTIFICATION DROPDOWN */}
                        
                        {/* BEGIN USER DROPDOWN */}
                        <li className="dropdown" id="user-header">
                            <a href={() => null} onClick={this.showDropdown} data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <span className="username">Hi, Admin</span>
                            </a>
                            <ul className={showDropdownMyAccount ? "dropdown-menu show" : "dropdown-menu"} ref={this.wrapperMyAccountRef}>
                                <li>
                                    <a href={() => null} onClick={() => this.logout()}><i className="icon-power" /><span>Logout</span></a>
                                </li>
                            </ul>

                        </li>
                        {/* END USER DROPDOWN */}
                    </ul>
                </div>
                {/* header-right */}
            </div>

        )
    }
}
