import React, { Component } from "react";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
// import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { toast } from 'react-toastify';
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'RESERVATION NUMBER',
        selector: row => row.bookingID,
        sortable: true,
    },
    {
        name: 'SURNAME',
        selector: row => row.lastName,
        sortable: true,
    },
    {
        name: 'PROPERTY',
        selector: row => row.propertyName,
        sortable: true,
        grow: 2,
    },
    {
        name: 'LENGTH OF STAY',
        selector: row => row.duration,
        sortable: true,
    },
    {
        name: 'DATE & TIME OF ARRIVAL',
        selector: row => row.reservationStart,
        sortable: true,
        grow: 2,
    },
    {
        name: 'REVENUE',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
    },
];

export default class Reservations extends Component {
    state = {
        dateRange: [{
            startDate: new Date(),
            endDate: addDays(new Date(), 30),
            key: 'selection'
        }],
        loading: true,
        noShowChecked: [],
        records: [],
    }
    componentDidMount() {
        let startDate = this.state.dateRange[0].startDate;
        let endDate = this.state.dateRange[0].endDate;
        this.setState({
            dateRange: [{
                startDate,
                endDate
            }],
        }, () => {
            this.fetchReservations();
        })
    }

    async fetchReservations() {
        const { dateRange } = this.state;
        let startDate = new Date(dateRange[0].startDate);
        let endDate = new Date(dateRange[0].endDate);
        this.setState({ loading: true });
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/reservation/NoshowResevation`, {
            "reservationStart": startDate,
            "reservationEnd": endDate
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                let array = [];
                response.data.forEach(element => {
                    if(!element.charge.captured){
                        array.push({
                            _id: element._id,
                            bookingId: element.bookingId,
                            bookingID: <a href={`/reservationDetails/${element._id}`}>{element.bookingId}</a>,
                            lastName: element.bookedBy && element.bookedBy.lastName ? element.bookedBy.lastName : 'N/A',
                            name: element.hotelId.name,
                            propertyName: <a href={`/editHotel/${element.hotelId._id}`}>{element.hotelId.name}</a>,
                            duration: element.duration + 'h',
                            reservationStart: moment(element.reservationStart).utc().format("MMM D YYYY hh:mm A"),
                            amount: '$ ' + parseFloat(element.charge.amount).toFixed(2),
                            action: <button className="btn btn-md btn-danger btn-block" onClick={() => this.approveCharge(element.charge._id, element.charge.captured)} disabled={element.charge.captured}>Approve</button>,
                        })
                    }
                });
                this.setState({
                    loading: false,
                    noShowChecked: [],
                    records: array
                })
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
    dateChange = (item, dateType) => {
        const { dateRange } = this.state;
        if (item) {
            let date = item.getTime();
            if (dateType === 'start') {
                this.setState({
                    dateRange: [{
                        startDate: date,
                        endDate: dateRange[0].endDate
                    }],
                }, () => {
                    this.fetchReservations();
                })
            } else {
                if (dateType === 'end') {
                    this.setState({
                        dateRange: [{
                            startDate: dateRange[0].startDate,
                            endDate: date
                        }],
                    }, () => {
                        this.fetchReservations();
                    })
                }
            }
        }
    }
    handlePageChange(pageNumber) {
        const { resevations, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const currentList = resevations.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ resevations, currentList, activePage: pageNumber, showingFrom, showingTo });
    }

    selectNoShowID = (e, type) => {
        const { noShowChecked, resevations } = this.state;
        const { checked } = e.target;
        if (checked) {
            if (type === 'all') {
                this.setState({
                    noShowChecked: []
                }, () => {
                    let selectID = noShowChecked;
                    resevations.forEach(data => {
                        if (!noShowChecked.includes(data._id) && !data.charge.captured) {
                            selectID.push(data._id);
                        }
                    })
                    this.setState({ noShowChecked: selectID })
                })
            } else {
                let selectID = noShowChecked;
                if (!noShowChecked.includes(type)) {
                    selectID.push(type);
                }
                this.setState({ noShowChecked: selectID })
            }
        } else {
            if (type === 'all') {
                this.setState({ noShowChecked: [] })
            } else {
                if (noShowChecked.includes(type)) {
                    let findIndex = noShowChecked.indexOf(type);
                    noShowChecked.splice(findIndex, 1);
                    this.setState({ noShowChecked })
                }
            }
        }
    }

    approveCharge = async (array, captured) => {
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/reservation/chargeStripeIntent`, {
            "charges": array
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                if (response.data === "success") {
                    toast.success('Approved Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.fetchReservations();
                } else {
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(error => {
                toast.error('Something went wrong', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(error);
            })

    }
    render() {
        const { history } = this.props
        const { dateRange, /*noShowChecked,*/ records, loading } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage </strong>No Shows</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">No Shows</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row" style={{ padding: 10, display: "flex", alignItems: 'center' }}>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>No Shows Listing</strong> </h3>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="row" style={{ display: "flex", alignItems: 'center' }}>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <DatePicker
                                                    selected={dateRange[0].startDate}
                                                    onChange={date => this.dateChange(date, 'start')}
                                                    selectsStart
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    startDate={dateRange[0].startDate}
                                                    endDate={dateRange[0].endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ textAlign: 'center' }}>
                                                <strong> to </strong>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <DatePicker
                                                    selected={dateRange[0].endDate}
                                                    onChange={date => this.dateChange(date, 'end')}
                                                    selectsEnd
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    startDate={dateRange[0].startDate}
                                                    endDate={dateRange[0].endDate}
                                                    minDate={dateRange[0].startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            {/*<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <button className="btn btn-md btn-danger btn-block" onClick={() => this.approveCharge('all', false)} disabled={noShowChecked.length < 1}>Approve All</button>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Reservation Number, Name, Property" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}