import $api from "../../utils/axios";
import React, { Component } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'rc-checkbox/assets/index.css';
import Dropzone from "react-dropzone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";

const Imagemaxsize = 1024*1024;
const acceptedFileTypes = 'image/jpeg, image/png'
const acceptedFiletypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

export default class ImageListing extends Component {
    state = {
        fullName: '',
        email: '',
        password: '',
        hotelName: '',
        hotelDescription: '',
        microStay: [],
        hotelRating: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        commRate: 0,

        Amenities: [],

        sameOwnerContact: false,
        sameOwnerManager: false,

        contactFullName: '',
        contactFirstName: '',
        contactLastName: '',
        contactPosition: '',
        contactNumber: '',
        contactEmail: '',

        managerFullName: '',
        managerFirstName: '',
        managerLastName: '',
        managerPosition: '',
        managerNumber: '',
        managerEmail: '',

        fullNameError: '',
        emailError: '',
        passwordError: '',

        propertyID: '',

        hotelMultiImageSrc: [],
        hotelMultiImage: [],
        hotelMultiImageVer: [],
        hotelMultiImageSqr: [],
        hotelOptions: [],
        hotelSelectName: '',
        hotelSelectId: '',
        
        horizontalTtile: '',
        horizontalDescription: '',
        hotelImageHor: [],
        hotelImageVer: [],
        hotelImageSqr: [],
        verticalTtile: '',
        squareTtile: '',
        squareDescription: '',
        verticalDescription: '',
        hotelMultiImageSrcHor: [],
        hotelMultiImageSrcVer: [],
        hotelMultiImageSrcSqr: [],
        imageId: '',
        linkSqr: '',
        linkHor: '',
        linkVer: '',
    }
    async componentDidMount() {
        let tokenLoad = localStorage.getItem("token");
        const resHotel = await $api.get("/imagesforyou/");
        this.setState({
            horizontalTtile: resHotel.data[0].horizontalTtile,
            horizontalDescription: resHotel.data[0].horizontalDescription,
            verticalTtile: resHotel.data[0].verticalTtile,
            verticalDescription: resHotel.data[0].verticalDescription,
            squareTtile: resHotel.data[0].squareTtile,
            squareDescription: resHotel.data[0].squareDescription,
            hotelImageHor: resHotel.data[0].photosHor,
            hotelImageVer: resHotel.data[0].photosVer,
            hotelImageSqr: resHotel.data[0].photosSqr,
            imageId: resHotel.data[0]._id,
            linkHor: resHotel.data[0].linkHor,
            linkVer: resHotel.data[0].linkVer,
            linkSqr: resHotel.data[0].linkSqr,
        })
        await axios
          .get(`${process.env.REACT_APP_API_URL}/user/me`, {
            headers: {
              Authorization: `Bearer ${tokenLoad}`,
            },
          })
          .then((response) => {
           this.setState({
            email: response.data.email,
            fullName: `${response.data.firstName} ${response.data.lastName}`
          });

          })
          .catch((error) => {
            console.log(error);
          });
      }

    handleOptionChange = async (event, selectedOption) => {
    this.setState({
        hotelSelectName: selectedOption.hotelName,
        hotelSelectId: selectedOption._id
    })
        //setCurrentTab(res.data[0]._id);
      };
    onChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    onChangeVer = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    onChangeSqr = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    onChangeLinkSqr = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    onChangeLinkHor = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    onChangeLinkVer = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    onAmenitiesChange = (e) => {
        const { value } = e.target;
        let amenitiesArray = this.state.Amenities;

        if (!amenitiesArray.includes(value)) {
            amenitiesArray.push(value)
        } else {
            var index = amenitiesArray.indexOf(value)
            if (index !== -1) {
                amenitiesArray.splice(index, 1);
            }
        }

        this.setState({
            Amenities: amenitiesArray
        })
    }

    onContactChange = (e) => {
        const { checked } = e.target;
        if(checked){
            this.setState({
                sameOwnerContact: true
            })
        }else{
            this.setState({
                sameOwnerContact: false
            })
        }
    }

    onManagerChange = (e) => {
        const { checked } = e.target;
        if(checked){
            this.setState({
                sameOwnerManager: true
            })
        }else{
            this.setState({
                sameOwnerManager: false
            })
        }
    }

    validate = () => {
        this.setState({
            fullNameError: '',
            emailError: '',
            passwordError: ''
        })
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

        let fullNameError = '';
        let emailError = '';
        let passwordError = '';

        if (this.state.fullName === '') {
            fullNameError = "Please Enter Full Name"
        }
        if (fullNameError) {
            this.setState({ fullNameError })
            return false
        }

        if (this.state.email === '') {
            emailError = "Please Enter Email"
        } else if (!regex.test(this.state.email.trim())) {
            emailError = "Please enter a valid Email Address"
        }
        if (emailError) {
            this.setState({ emailError })
            return false
        }

        if (this.state.password === '') {
            passwordError = "Please Enter Password"
        }
        if (passwordError) {
            this.setState({ passwordError })
            return false
        }
        return true
    }

    changeRating = (val) => {
        this.setState({
            hotelRating: val
        })
    }

    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            horizontalDescription: data
        })
    }

    handleEditorChangeVer = (event, editor) => {
        const data = editor.getData();
        this.setState({
            verticalDescription: data
        })
    }
    handleEditorChangeSqr = (event, editor) => {
        const data = editor.getData();
        this.setState({
            squareDescription: data
        })
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0];
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > Imagemaxsize) {
                //alert("This File Is Too Big")
                return false
            }

            if (!acceptedFiletypesArray.includes(currentFileType)) {
                //alert("This File Is Not Supported")
                return false
            }
            return true
        }
    }

    onHandleondrop = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ hotelMultiImageSrcHor: images, hotelMultiImage: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            } else {
              toast.error("Please upload file within 1 MB", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              });               
          }
        }
    }

    onHandleondropVer = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ hotelMultiImageSrcVer: images, hotelMultiImageVer: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    onHandleondropSqr = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ hotelMultiImageSrcSqr: images, hotelMultiImageSqr: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    handleRemoveimage = (index, name, src) => () => {
        let imgSrc = [];
        let originalImage = [];
        if (name === 'hotelMultiImage') {
            imgSrc = [...this.state.hotelMultiImageSrcHor];
            originalImage = [...this.state.hotelMultiImage];
        }
        if (name === 'hotelMultiImageVer') {
            imgSrc = [...this.state.hotelMultiImageSrcVer];
            originalImage = [...this.state.hotelMultiImageVer];
        }
        if (name === 'hotelMultiImageSqr') {
            imgSrc = [...this.state.hotelMultiImageSrcSqr];
            originalImage = [...this.state.hotelMultiImageSqr];
        }
        imgSrc.splice(index, 1);
        originalImage.splice(index, 1);
        if (name === 'hotelMultiImage') {
            this.setState({ hotelMultiImageSrcHor: imgSrc, hotelMultiImage: originalImage });
        }
        if (name === 'hotelMultiImageVer') {
            this.setState({ hotelMultiImageSrcVer: imgSrc, hotelMultiImageVer: originalImage });
        }
        if (name === 'hotelMultiImageSqr') {
            this.setState({ hotelMultiImageSrcSqr: imgSrc, hotelMultiImageSqr: originalImage });
        }
    }

    microStay = (e) => {
        const { value } = e.target;
        let microStayArray = this.state.microStay;

        if (!microStayArray.includes(value)) {
            microStayArray.push(value)
        } else {
            var index = microStayArray.indexOf(value)
            if (index !== -1) {
                microStayArray.splice(index, 1);
            }
        }

        this.setState({
            microStay: microStayArray
        })
    }

    handleChange = address => {
        this.setState({ streetAddress: address });
    };

    renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
            <input {...getInputProps()} className="form-control" placeholder="Enter the street address here" />
            <div className="autocomplete-dropdown-container">
                {suggestions.map((suggestion, index) => (
                    <option {...getSuggestionItemProps(suggestion)} key={index}>
                        {suggestion.description}
                    </option>
                ))}
            </div>
        </div>
    );
    deleteImage = async (ImageUri,imgTyp) => {
        let imageId = this.state.imageId;
        const imageUrl = { url: ImageUri, imgTyp: imgTyp };
        let token = await localStorage.getItem("token");
    
        await axios
          .put(`${process.env.REACT_APP_API_URL}/imagesforyou/${imageId}/deletehorversqr`, imageUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (response) => {
            if (response.data === "OK") {
              const resHotel = await $api.get("/imagesforyou/");
              this.setState({
                  hotelImageHor: resHotel.data[0].photosHor,
                  hotelImageVer: resHotel.data[0].photosVer,
                  hotelImageSqr: resHotel.data[0].photosSqr,
              })
              toast.success("Image deleted Successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error("Image deletion failed", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

    submitFunction = async (e) => {
        e.preventDefault();

                const { horizontalTtile, horizontalDescription, verticalTtile, verticalDescription, squareTtile, squareDescription, hotelMultiImage, hotelMultiImageVer, hotelMultiImageSqr, imageId, linkSqr, linkVer, linkHor } = this.state;
                let parameters = {
                    "horizontalTtile": horizontalTtile,
                    "horizontalDescription": horizontalDescription.replace(/<[^>]*>/g, ''),
                    "verticalTtile": verticalTtile,
                    "verticalDescription": verticalDescription.replace(/<[^>]*>/g, ''),
                    "squareTtile": squareTtile,
                    "squareDescription": squareDescription.replace(/<[^>]*>/g, ''),
                    "imageId": imageId,
                    "linkSqr": linkSqr,
                    "linkVer": linkVer,
                    "linkHor": linkHor,
                };
                await axios.post(`${process.env.REACT_APP_API_URL}/imagesforyou/addimage`, parameters, {
                    headers: {
                        'Content-type': 'application/json',
                    },
                })
                .then(async hotelResponse => {
                    if (hotelResponse.status === "201" || hotelResponse.status === 201) {
                        toast.success('Data Updated Successfully', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    if( hotelMultiImage.length > 0 ) {
                        for (let i = 0; i <= hotelMultiImage.length - 1; i++) {
                            var hotelPhotosForm = new FormData();
                            hotelPhotosForm.append('photos', hotelMultiImage[i], hotelMultiImage[i].name);

                            await axios({
                                method: 'post',
                                url: `${process.env.REACT_APP_API_URL}/imagesforyou/${hotelResponse.data._id}/imageshor`,
                                data: hotelPhotosForm,
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(managerResponse => {
                                toast.success('Horizontal Image Added Successfully', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            })
                            .catch(error => {
                                console.log(error);
                            })
                        }
                    }
                    if( hotelMultiImageVer.length > 0 ) {
                        for (let i = 0; i <= hotelMultiImageVer.length - 1; i++) {
                            var hotelPhotosFormVer = new FormData();
                            hotelPhotosFormVer.append('photos', hotelMultiImageVer[i], hotelMultiImageVer[i].name);

                            await axios({
                                method: 'post',
                                url: `${process.env.REACT_APP_API_URL}/imagesforyou/${hotelResponse.data._id}/imagesver`,
                                data: hotelPhotosFormVer,
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(managerResponse => {
                                toast.success('Vertical Image Added Successfully', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            })
                            .catch(error => {
                                console.log(error);
                            })
                        }
                    }
                    if( hotelMultiImageSqr.length > 0 ) {
                        for (let i = 0; i <= hotelMultiImageSqr.length - 1; i++) {
                            var hotelPhotosFormSqr = new FormData();
                            hotelPhotosFormSqr.append('photos', hotelMultiImageSqr[i], hotelMultiImageSqr[i].name);

                            await axios({
                                method: 'post',
                                url: `${process.env.REACT_APP_API_URL}/imagesforyou/${hotelResponse.data._id}/imagessqr`,
                                data: hotelPhotosFormSqr,
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(managerResponse => {
                                toast.success('Square Image Added Successfully', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            })
                            .catch(error => {
                                console.log(error);
                            })
                        }
                    }
                    window.location.reload();
                    }
                })
                .catch(err => {
                    toast.error(err, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
    }

    render() {
        const { history } = this.props
        const { hotelMultiImageSrcHor, hotelMultiImageSrcVer, hotelMultiImageSrcSqr } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Images</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                              <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li>
                              <a href={() => null} onClick={() => history.push("/all-banners")}>Image Management</a>
                            </li>
                            <li className="active">Add Image</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <form onSubmit={this.submitFunction}>
                            <div className="panel">
                                <div className="panel-header ">
                                    <h3><strong>Add Horizontal Image</strong> </h3>
                                </div>
                                <div className="panel-content">
                                        <h5>Title</h5>
                                        <hr />
                                        <div className="form-group">
                    <input
                    type="text"
                    name="horizontalTtile"
                    className="form-control"
                    placeholder="Enter the title here"
                    aria-required="true"
                    onChange={this.onChange}
                    defaultValue={this.state.horizontalTtile}
                  />
                                </div>
                                </div>
                                <div className="panel-content">
                                        <h5>Link</h5>
                                        <hr />
                                        <div className="form-group">
                    <input
                    type="text"
                    name="linkHor"
                    className="form-control"
                    placeholder="Enter the link here"
                    aria-required="true"
                    onChange={this.onChangeLinkHor}
                    defaultValue={this.state.linkHor}
                  />
                                </div>
                                </div>
                                <div className="panel-content">
                                        <h5>Description</h5>
                                        <hr />
                                        <div className="form-group">
                    <CKEditor
                    className="summernote bg-white"
                    editor={ClassicEditor}
                    data={this.state.horizontalDescription !== undefined ? this.state.horizontalDescription : ""}
                    onInit={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle("height", "150px", editor.editing.view.document.getRoot());
                      });
                    }}
                    onChange={this.handleEditorChange}
                  />
                                    </div>
                                    </div>
                                <div className="panel-content">
                                    <h5><strong>PHOTOS</strong></h5>
                                    <h className="text-danger">(*Max image size 1 MB, e.g. 472 x 192)</h>
                                    <hr />
                                    <div className="form-group">
                                    <Dropzone onDrop={this.onHandleondrop.bind(this)} accept={acceptedFileTypes} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ border: "2px dashed #ef4a23", minHeight: "auto", padding: "10px", textAlign: "center" }}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="hotelPhotos" />
                          <label style={{ marginTop: 25 }}>
                            <div className="dz-message ">
                              <i className="ti-plus text-primary font-26 " />
                              <div className="mt-2 font-15 mb-4" style={{ color: "#474949" }}>
                                Click To Upload Images Here
                              </div>
                            </div>
                          </label>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {hotelMultiImageSrcHor.length > 0 || this.state.hotelImageHor.length > 0 ? (
                    <div style={{ border: "1px solid #ccc", padding: 20, height: "550px", overflowY: "scroll", overflowX: "hidden" }}>
                      <div className="row">
                        {hotelMultiImageSrcHor &&
                          hotelMultiImageSrcHor.map((ImageUri, index) => {
                            return (
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                                <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger btn-block"
                                  style={{ marginLeft: 0, marginTop: 10 }}
                                  onClick={this.handleRemoveimage(index, "hotelMultiImage", "hotelMultiImageSrcHor")}
                                >
                                  Delete
                                </button>
                              </div>
                            );
                          })}
                        {this.state.hotelImageHor.map((ImageUri, index) => {
                          return (
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                              <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                              <button type="button" className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri,'Hor')}>
                                Delete
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : this.state.imageError || this.state.hotelImageHor.length === 0 || this.state.hotelMultiImageSrcHor.length === 0 ? (
                    <div className="text-danger">*Please upload valid image and image max size should be 1 MB.</div>
                  ) : null}
                                    </div>
                                </div>
<hr />
<div className="panel-header ">
                                    <h3><strong>Add Vertical Image</strong> </h3>
                                </div>
                                <div className="panel-content">
                                        <h5>Title</h5>
                                        <hr />
                                        <div className="form-group">
                                        <input
                    type="text"
                    name="verticalTtile"
                    className="form-control"
                    placeholder="Enter the title here"
                    aria-required="true"
                    onChange={this.onChangeVer}
                    defaultValue={this.state.verticalTtile}
                  />
                                </div>
                                </div>
                                <div className="panel-content">
                                        <h5>Link</h5>
                                        <hr />
                                        <div className="form-group">
                    <input
                    type="text"
                    name="linkVer"
                    className="form-control"
                    placeholder="Enter the link here"
                    aria-required="true"
                    onChange={this.onChangeLinkVer}
                    defaultValue={this.state.linkVer}
                  />
                                </div>
                                </div>
                                <div className="panel-content">
                                        <h5>Description</h5>
                                        <hr />
                                        <div className="form-group">
                                        <CKEditor
                    className="summernote bg-white"
                    editor={ClassicEditor}
                    data={this.state.verticalDescription !== undefined ? this.state.verticalDescription : ""}
                    onInit={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle("height", "150px", editor.editing.view.document.getRoot());
                      });
                    }}
                    onChange={this.handleEditorChangeVer}
                  />
                                    </div>
                                    </div>
                                <div className="panel-content">
                                    <h5><strong>PHOTOS</strong></h5>
                                    <h className="text-danger">(*Max image size 1 MB, e.g. 497 x 749)</h>
                                    <hr />
                                    <div className="form-group">
                                    <Dropzone onDrop={this.onHandleondropVer.bind(this)} accept={acceptedFileTypes} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ border: "2px dashed #ef4a23", minHeight: "auto", padding: "10px", textAlign: "center" }}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="hotelPhotos" />
                          <label style={{ marginTop: 25 }}>
                            <div className="dz-message ">
                              <i className="ti-plus text-primary font-26 " />
                              <div className="mt-2 font-15 mb-4" style={{ color: "#474949" }}>
                                Click To Upload Images Here
                              </div>
                            </div>
                          </label>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                    {hotelMultiImageSrcVer.length > 0 || this.state.hotelImageVer.length > 0 ? (
                    <div style={{ border: "1px solid #ccc", padding: 20, height: "550px", overflowY: "scroll", overflowX: "hidden" }}>
                      <div className="row">
                        {hotelMultiImageSrcVer &&
                          hotelMultiImageSrcVer.map((ImageUri, index) => {
                            return (
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                                <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger btn-block"
                                  style={{ marginLeft: 0, marginTop: 10 }}
                                  onClick={this.handleRemoveimage(index, "hotelMultiImageVer", "hotelMultiImageSrcVer")}
                                >
                                  Delete
                                </button>
                              </div>
                            );
                          })}
                        {this.state.hotelImageVer.map((ImageUri, index) => {
                          return (
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                              <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                              <button type="button" className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri,'Ver')}>
                                Delete
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : this.state.imageError || this.state.hotelImageVer.length === 0 || this.state.hotelMultiImageSrcVer.length === 0 ? (
                    <div className="text-danger">*Please upload valid image and image max size should be 1 MB.</div>
                  ) : null}
                                    </div>
                                </div>
<hr />
<div className="panel-header ">
                                    <h3><strong>Add Square Image</strong> </h3>
                                </div>
                                <div className="panel-content">
                                        <h5>Title</h5>
                                        <hr />
                                        <div className="form-group">
                                        <input
                    type="text"
                    name="squareTtile"
                    className="form-control"
                    placeholder="Enter the title here"
                    aria-required="true"
                    onChange={this.onChangeSqr}
                    defaultValue={this.state.squareTtile}
                  />                                     
                                        </div>
                                </div>
                                <div className="panel-content">
                                        <h5>Link</h5>
                                        <hr />
                                        <div className="form-group">
                    <input
                    type="text"
                    name="linkSqr"
                    className="form-control"
                    placeholder="Enter the link here"
                    aria-required="true"
                    onChange={this.onChangeLinkSqr}
                    defaultValue={this.state.linkSqr}
                  />
                                </div>
                                </div>
                                <div className="panel-content">
                                        <h5>Description</h5>
                                        <hr />
                                        <div className="form-group">
                                        <CKEditor
                    className="summernote bg-white"
                    editor={ClassicEditor}
                    data={this.state.squareDescription !== undefined ? this.state.squareDescription : ""}
                    onInit={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle("height", "150px", editor.editing.view.document.getRoot());
                      });
                    }}
                    onChange={this.handleEditorChangeSqr}
                  />
                                    </div>
                                    </div>
                                <div className="panel-content">
                                    <h5><strong>PHOTOS</strong></h5>
                                    <h className="text-danger">(*Max image size 1 MB, e.g. 472 x 480)</h>
                                    <hr />
                                    <div className="form-group">
                                    <Dropzone onDrop={this.onHandleondropSqr.bind(this)} accept={acceptedFileTypes} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ border: "2px dashed #ef4a23", minHeight: "auto", padding: "10px", textAlign: "center" }}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="hotelPhotos" />
                          <label style={{ marginTop: 25 }}>
                            <div className="dz-message ">
                              <i className="ti-plus text-primary font-26 " />
                              <div className="mt-2 font-15 mb-4" style={{ color: "#474949" }}>
                                Click To Upload Images Here
                              </div>
                            </div>
                          </label>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                    {hotelMultiImageSrcSqr.length > 0 || this.state.hotelImageSqr.length > 0 ? (
                    <div style={{ border: "1px solid #ccc", padding: 20, height: "550px", overflowY: "scroll", overflowX: "hidden" }}>
                      <div className="row">
                        {hotelMultiImageSrcSqr &&
                          hotelMultiImageSrcSqr.map((ImageUri, index) => {
                            return (
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                                <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger btn-block"
                                  style={{ marginLeft: 0, marginTop: 10 }}
                                  onClick={this.handleRemoveimage(index, "hotelMultiImageSqr", "hotelMultiImageSrcSqr")}
                                >
                                  Delete
                                </button>
                              </div>
                            );
                          })}
                        {this.state.hotelImageSqr.map((ImageUri, index) => {
                          return (
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                              <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                              <button type="button" className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri,'Sqr')}>
                                Delete
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : this.state.imageError || this.state.hotelImageSqr.length === 0 ? (
                    <div className="text-danger">*Please upload valid image and image max size should be 1 MB.</div>
                  ) : null}
                                    </div>
                                </div>
                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger">Submit</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
