import React, { Component } from 'react';
import axios from 'axios';
import Loader from "../../components/Loader";
import moment from 'moment';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import Progress from 'react-progressbar';

export default class ReservationDetails extends Component {
    constructor() {
        super();
        this.state = {
            reviews: [],
            loading: true,
            blockedUser: [],
            blockLinkedProperties: false,
            alertShow: false,
            alertBoxTitle: '',
            alertBoxType: '',
            alertBoxData: null,
            hotelImage: null,
            width: 0,
        }
        window.addEventListener("resize", this.update);
    }
    update = () => {
        this.setState({
            width: window.innerWidth
        });
    };
    componentDidMount(){
        this.update();
        this.fetchHotelDetails();
    }
    onCancel = () => {
        this.setState({
            alertShow: false,
            alertBoxTitle: '',
            alertBoxType: '',
            alertBoxData: null,
        })
    }

    async fetchHotelDetails(){
        let token = await localStorage.getItem('token');
        let hotelId = this.props.match.params.hotelId;
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if(response.status === 200) {
                let filterHotelId = response.data.filter((eachHotelData) => {
                    return eachHotelData._id === hotelId;
                });
                this.setState({
                    hotelImage: filterHotelId[0].photos[0]
                },() => {
                    this.fetchReviews();
                })
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    async fetchReviews(){
        let token = await localStorage.getItem('token');
        let hotelId = this.props.match.params.hotelId;
        await axios.get(`${process.env.REACT_APP_API_URL}/review/hotel/${hotelId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    this.setState({
                        reviews: response.data,
                        loading: false
                    })
                } else {
                    this.setState({
                        reviews: [],
                        loading: false
                    })
                }
            }
        })
    }
    showAlertBox = (value,alertType) => {
        let title = '';
        if(alertType === 'Review'){
            title = `Remove Review ?`;
        }
        this.setState({
            alertBoxData: value,
            alertBoxType: alertType,
            alertBoxTitle: title,
            alertShow: true,
        })
    }

    confirmFunc(){
        const {alertBoxType, alertBoxData} = this.state;
        this.setState({
            alertShow: false
        }, () => {
            if(alertBoxType === 'Review'){
                this.removeReview(alertBoxData);
            }
        })
    }

    fetchName(name) {
        let splitName = name.split(" ");
        let fName = '';
        if(splitName.length > 0) {
            fName = splitName[0];
        } else {
            fName = name;
        }
        return fName;
    }

    removeReview = async(reviewData) => {
        let token = await localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/review/${reviewData._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if(response.status === 200){
                toast.success('Review deleted successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.componentDidMount();
            } else {
                toast.error('Something went wrong, Please try again...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(error => {
            toast.error(error.response.data.error, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    render() {
        const { history } = this.props
        const {reviews, loading, alertShow, alertBoxTitle, hotelImage, width} = this.state;
        if(loading) {
            return(
                <div>
                    <div className="header">
                        <h2><strong>Manage</strong> Review</h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li>
                                    <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                                </li>
                                <li>
                                    <a href={() => null} onClick={() => history.push("/property/all")}>Property Listing</a>
                                </li>
                                <li className="active">Reviews</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlignLast: 'center'}}>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="header">
                        <SweetAlert
                            show={alertShow}
                            warning
                            showCancel
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="danger"
                            title={alertBoxTitle}
                            onConfirm={() => this.confirmFunc()}
                            onCancel={() => this.onCancel()}
                            focusCancelBtn
                        />
                        <h2><strong>Manage</strong> Reservation Details</h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li>
                                    <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                                </li>
                                <li>
                                    <a href={() => null} onClick={() => history.push("/property/all")}>Property Listing</a>
                                </li>
                                <li className="active">Reviews</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="panel">
                                {
                                    reviews.length > 0 ?
                                    <div>
                                        <div className="panel-header ">
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                                    <h3><strong>Customer Reviews</strong> </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-content" style={{paddingLeft: 20}}>
                                            <div className='row' style={{marginTop: 10}}>
                                            </div>
                                            {
                                                reviews.map((resevationsDetails, index) => {
                                                    return(
                                                        <div className='review-comment' key={index}>
                                                            <div className='row'>
                                                                <div className='col-lg-11 col-md-11 col-sm-12 col-xs-12'>
                                                                    {
                                                                        resevationsDetails.tags.map((data, index) => {
                                                                            return (
                                                                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12' key={index}>
                                                                                    <div className='row' style={{display:'flex', alignItems:'center', marginTop: 5}}>
                                                                                        <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                                                            <span>{data.key}</span>
                                                                                        </div>
                                                                                        <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12'>
                                                                                            <Progress completed={((data.value / 5) * 100)} color={"#FF4500"} />
                                                                                        </div>
                                                                                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                                                                            <span>{data.value}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='col-lg-1 col-md-1 col-sm-12 col-xs-12'>
                                                                    <button className="btn btn-danger" onClick={() => this.showAlertBox(resevationsDetails,'Review')} style={{float: 'right'}}>Remove</button>
                                                                </div>
                                                            </div>
                                                        
                                                            <div className='row' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <div className='col-lg-1 col-md-1 col-sm-1 col-xs-12'>
                                                                    <div style={{width:50, height: 50, borderRadius: '50%', backgroundColor:'#FF4500', color: '#fff', display: 'flex', justifyContent:'center', alignItems:'center', fontSize: 18, fontWeight: 'bold'}}>{this.fetchName(resevationsDetails.userId.fullName)[0]}</div>
                                                                </div>
                                                                <div className='col-lg-11 col-md-11 col-sm-11 col-xs-12' style={{marginTop: 10,}}>
                                                                    <strong style={{fontWeight: 'bold'}}>{this.fetchName(resevationsDetails.userId.fullName)}</strong>
                                                                    <p style={{fontSize: 10}}>{moment(resevationsDetails.createdAt ).format('MMM DD YYYY')}</p>
                                                                </div>
                                                            </div>
                                                            <p style={{padding: 5}}>{resevationsDetails.comment}</p>
                                                            {
                                                                resevationsDetails.reply ?
                                                                <div className={width > 900 ? 'row admin-response' : 'row admin-response-no-border'}>
                                                                    <div className='row' style={hotelImage ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : {display: 'flex', alignItems: 'center'}}>
                                                                        {
                                                                            hotelImage ? 
                                                                            <div className='col-lg-1 col-md-1 col-sm-1 col-xs-12'>
                                                                                <img src={hotelImage} alt={hotelImage} style={{width: 50, height: 50, borderRadius: '50%', float: 'right'}} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        <div className='col-lg-11 col-md-11 col-sm-11 col-xs-12'>
                                                                            <strong style={{fontWeight: 'bold'}}>{resevationsDetails.hotelId.name}</strong>
                                                                            <p style={{fontSize: 10}}>{moment(resevationsDetails.updatedAt ).format('MMM DD YYYY')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <p style={{padding: 5}}>{resevationsDetails.reply}</p>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="panel-header ">
                                            <h3><strong>Customer Review</strong> </h3>
                                        </div>
                                        <div className="panel-content" style={{paddingLeft: 20}}>
                                            <h2>Not Reviewed yet</h2>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}