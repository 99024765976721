import React from "react";
import { useHistory } from "react-router-dom";

const Footer = () => {
    const history = useHistory();
    return (
        <div className="footer">
            <div className="copyright">
                <p className="pull-left sm-pull-reset">
                    <span>
                        Copyright <span className="copyright">©</span> 2020{" "}
                    </span>
                    <span>TimeFlyz</span>.<span> All rights reserved. </span>
                </p>
                <p className="pull-right sm-pull-reset">
                    <span>
                        <a
                            href={() => null}
                            onClick={() => history.push("/contactUs")}
                            className="m-r-10"
                        >
                            Support
                        </a>
                        |
                        <a
                            href={() => null}
                            onClick={() => history.push("/terms")}
                            className="m-l-10 m-r-10"
                        >
                            Terms of use
                        </a>
                        |
                        <a href={() => null}
                            onClick={() => history.push("/privacy")} className="m-l-10">
                            Privacy Policy
                        </a>
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Footer;
