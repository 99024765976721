import { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Logo from "../../../assets/images/TimeFlyz.png";

const Sidebar = ({ toggleSidebar }) => {
    const history = useHistory();
    const [activeSidebar, setActiveSidebar] = useState("");
    const pageName = window.location.pathname.split("/");

    const handleActiveSidebar = (title) => {
        setActiveSidebar((prev) => (prev === title ? "" : title));
    };

    const websiteAllActiveStatus = useMemo(
        () =>
            activeSidebar === "websiteAllSidebar" ||
            activeSidebar === "contactUsSidebar" ||
            activeSidebar === "userTrmsSidebar" ||
            activeSidebar === "hotelTermsSidebar" ||
            activeSidebar === "privacySidebar" ||
            activeSidebar === "aboutSidebar" ||
            activeSidebar === "pressRoomSidebar" ||
            pageName[1] === "contactUs" ||
            pageName[1] === "terms" ||
            pageName[1] === "terms-hotel" ||
            pageName[1] === "privacy" ||
            pageName[1] === "faq" ||
            pageName[1] === "about" ||
            pageName[1] === "pressRoom",
        [activeSidebar, pageName]
    );

    const handleRedirect = (route) => {
        history.push(route);
    };

    const logout = () => {
        localStorage.removeItem("token");
        handleRedirect("/login");
    };

    const lockScreen = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/me`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                localStorage.setItem("userEmail", response.data.email);
                localStorage.removeItem("token");
                history.push("/lock");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (pageName[1] === "dashboard") {
            setActiveSidebar("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName[1]]);

    return (
        <div
            className="sidebar"
            style={{ display: toggleSidebar ? "block" : "none" }}
        >
            <div className="logopanel">
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        handleActiveSidebar("");
                        handleRedirect("/dashboard");
                    }}
                >
                    <img src={Logo} alt="Logo" style={{ height: "150%" }} />
                </span>
            </div>
            <div className="sidebar-inner">
                <div className="sidebar-top">
                    <div className="userlogged clearfix">
                        <h4 style={{ textAlign: "center" }}>Hello Admin</h4>
                    </div>
                </div>
                <ul className="nav nav-sidebar enable-scroll">
                    <li
                        className={
                            websiteAllActiveStatus
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleActiveSidebar("websiteAllSidebar");
                            }}
                        >
                            <i className="fa fa-edit"></i>
                            <span>Website </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={
                            activeSidebar === "contactUsSidebar" ||
                            pageName[1] === "contactUs"
                                ? "nav-parent active"
                                : "nav-parent"
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("contactUsSidebar")
                            }
                        >
                            <span>Contact Us</span>
                            <span className="fa arrow"></span>
                        </a>
                        <ul className="children collapse">
                            <li
                                className={
                                    pageName[1] === "contactUs" &&
                                    pageName[2] === "list"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/contactUs/list");
                                    }}
                                >
                                    List
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "contactUs" &&
                                    pageName[2] === "subscription"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect(
                                            "/contactUs/subscription"
                                        );
                                    }}
                                >
                                    Subscription
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "contactUs" &&
                                    pageName[2] === undefined
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/contactUs");
                                    }}
                                >
                                    Edit
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "contactUs" &&
                                    pageName[2] === "seo"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/contactUs/seo");
                                    }}
                                >
                                    SEO
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            activeSidebar === "userTrmsSidebar" ||
                            pageName[1] === "terms"
                                ? "nav-parent active"
                                : "nav-parent"
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("userTrmsSidebar")
                            }
                        >
                            <span>User Terms & Conditions</span>
                            <span className="fa arrow"></span>
                        </a>
                        <ul className="children collapse">
                            <li
                                className={
                                    pageName[1] === "terms" &&
                                    pageName[2] === undefined
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/terms");
                                    }}
                                >
                                    Edit
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "terms" &&
                                    pageName[2] === "seo"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/terms/seo");
                                    }}
                                >
                                    SEO
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            activeSidebar === "hotelTermsSidebar" ||
                            pageName[1] === "terms-hotel"
                                ? "nav-parent active"
                                : "nav-parent"
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("hotelTermsSidebar")
                            }
                        >
                            <span>Hotel Terms & Conditions</span>
                            <span className="fa arrow"></span>
                        </a>
                        <ul className="children collapse">
                            <li
                                className={
                                    pageName[1] === "terms-hotel" &&
                                    pageName[2] === undefined
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/terms-hotel");
                                    }}
                                >
                                    Edit
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "terms-hotel" &&
                                    pageName[2] === "seo"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/terms-hotel/seo");
                                    }}
                                >
                                    SEO
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            activeSidebar === "privacySidebar" ||
                            pageName[1] === "privacy"
                                ? "nav-parent active"
                                : "nav-parent"
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("privacySidebar")
                            }
                        >
                            <span>Privacy Policy</span>
                            <span className="fa arrow"></span>
                        </a>
                        <ul className="children collapse">
                            <li
                                className={
                                    pageName[1] === "privacy" &&
                                    pageName[2] === undefined
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/privacy");
                                    }}
                                >
                                    Edit
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "privacy" &&
                                    pageName[2] === "seo"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/privacy/seo");
                                    }}
                                >
                                    SEO
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            activeSidebar === "faqSidebar" ||
                            pageName[1] === "faq"
                                ? "active"
                                : ""
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleActiveSidebar("faqSidebar");
                                handleRedirect("/faq");
                            }}
                        >
                            <span>FAQs</span>
                        </a>
                    </li>

                    <li
                        className={
                            activeSidebar === "aboutSidebar" ||
                            pageName[1] === "about"
                                ? "nav-parent active"
                                : "nav-parent"
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() => handleActiveSidebar("aboutSidebar")}
                        >
                            <span>About TimeFlyz</span>{" "}
                            <span className="fa arrow"></span>
                        </a>
                        <ul className="children collapse">
                            <li
                                className={
                                    pageName[1] === "about" &&
                                    pageName[2] === undefined
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/about");
                                    }}
                                >
                                    Edit
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "about" &&
                                    pageName[2] === "seo"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/about/seo");
                                    }}
                                >
                                    SEO
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            activeSidebar === "pressRoomSidebar" ||
                            pageName[1] === "pressRoom"
                                ? "nav-parent active"
                                : "nav-parent"
                        }
                        style={{
                            display: websiteAllActiveStatus ? "block" : "none",
                        }}
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("pressRoomSidebar")
                            }
                        >
                            <span>Press Room</span>
                            <span className="fa arrow"></span>
                        </a>
                        <ul className="children collapse">
                            <li
                                className={
                                    pageName[1] === "pressRoom" &&
                                    pageName[2] === undefined
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/pressRoom");
                                    }}
                                >
                                    Edit
                                </a>
                            </li>
                            <li
                                className={
                                    pageName[1] === "pressRoom" &&
                                    pageName[2] === "seo"
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() => {
                                        handleRedirect("/pressRoom/seo");
                                    }}
                                >
                                    SEO
                                </a>
                            </li>
                        </ul>
                    </li>

                    {/* Properties Section Starts */}
                    <li
                        className={
                            activeSidebar === "propertyAllSidebar" ||
                            pageName[1] === "property" ||
                            pageName[1] === "addHotel" ||
                            pageName[1] === "editHotel" ||
                            pageName[1] === "billing" ||
                            pageName[1] === "billingDetails" ||
                            pageName[1] === "users"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("propertyAllSidebar")
                            }
                        >
                            <i className="fa fa-building"></i>
                            <span>Properties </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "property" ||
                            pageName[1] === "addHotel" ||
                            pageName[1] === "editHotel" ||
                            pageName[1] === "billing" ||
                            pageName[1] === "billingDetails"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "propertyAllSidebar" ||
                            pageName[1] === "property" ||
                            pageName[1] === "addHotel" ||
                            pageName[1] === "editHotel" ||
                            pageName[1] === "billing" ||
                            pageName[1] === "billingDetails" ||
                            pageName[1] === "users"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/property/all");
                            }}
                        >
                            <span>All Properties</span>
                        </a>
                    </li>
                    <li
                        className={pageName[1] === "users" ? "active" : ""}
                        style={
                            activeSidebar === "propertyAllSidebar" ||
                            pageName[1] === "property" ||
                            pageName[1] === "addHotel" ||
                            pageName[1] === "editHotel" ||
                            pageName[1] === "billing" ||
                            pageName[1] === "billingDetails" ||
                            pageName[1] === "users"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/users");
                            }}
                        >
                            <span>Users</span>
                        </a>
                    </li>
                    {/* Properties Section Ends */}
                    {/* Coupons Section Starts */}
                    <li
                        className={
                            pageName[1] === "coupons"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleActiveSidebar("")
                                handleRedirect("/coupons");
                            }}
                        >
                            <i className="fa fa-file"></i>
                            <span>Coupons </span>
                        </a>
                    </li>
                    {/* Coupons Section Ends */}
                    {/* Reporting Section Starts */}
                    <li
                        className={
                            activeSidebar === "reportingAllSidebar" ||
                            pageName[1] === "reporting" ||
                            pageName[1] === "expediaURLError" ||
                            pageName[1] === "monthlyBillingPaymentFails" ||
                            pageName[1] === "missingPropertyID" ||
                            pageName[1] === "reportingPropertyType"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("reportingAllSidebar")
                            }
                        >
                            <i className="fa fa-file"></i>
                            <span>Reporting </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={pageName[1] === "reporting" ? "active" : ""}
                        style={
                            activeSidebar === "reportingAllSidebar" ||
                            pageName[1] === "reporting" ||
                            pageName[1] === "expediaURLError" ||
                            pageName[1] === "monthlyBillingPaymentFails" ||
                            pageName[1] === "missingPropertyID" ||
                            pageName[1] === "reportingPropertyType"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/reporting");
                            }}
                        >
                            <span>Hotel Reporting</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "expediaURLError" ? "active" : ""
                        }
                        style={
                            activeSidebar === "reportingAllSidebar" ||
                            pageName[1] === "reporting" ||
                            pageName[1] === "expediaURLError" ||
                            pageName[1] === "monthlyBillingPaymentFails" ||
                            pageName[1] === "missingPropertyID" ||
                            pageName[1] === "reportingPropertyType"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/expediaURLError");
                            }}
                        >
                            <span>Expedia URL Reporting</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "monthlyBillingPaymentFails"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "reportingAllSidebar" ||
                            pageName[1] === "reporting" ||
                            pageName[1] === "expediaURLError" ||
                            pageName[1] === "monthlyBillingPaymentFails" ||
                            pageName[1] === "missingPropertyID" ||
                            pageName[1] === "reportingPropertyType"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/monthlyBillingPaymentFails");
                            }}
                        >
                            <span>Monthly Billing Payment Fails</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "missingPropertyID" ? "active" : ""
                        }
                        style={
                            activeSidebar === "reportingAllSidebar" ||
                            pageName[1] === "reporting" ||
                            pageName[1] === "expediaURLError" ||
                            pageName[1] === "monthlyBillingPaymentFails" ||
                            pageName[1] === "missingPropertyID" ||
                            pageName[1] === "reportingPropertyType"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/missingPropertyID");
                            }}
                        >
                            <span>Missing Property ID</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "reportingPropertyType"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "reportingAllSidebar" ||
                            pageName[1] === "reporting" ||
                            pageName[1] === "expediaURLError" ||
                            pageName[1] === "monthlyBillingPaymentFails" ||
                            pageName[1] === "missingPropertyID" ||
                            pageName[1] === "reportingPropertyType"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/reportingPropertyType");
                            }}
                        >
                            <span>Hotel Property Chain</span>
                        </a>
                    </li>
                    {/* Reporting Section Ends */}

                    {/* Property Chain Section Starts */}
                    <li
                        className={
                            activeSidebar === "propertyTypeAllSidebar" ||
                            pageName[1] === "propertyType" ||
                            pageName[1] === "groupRegionalManagers"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("propertyTypeAllSidebar")
                            }
                        >
                            <i className="fa fa-file"></i>
                            <span>Property Chain </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "propertyType" ? "active" : ""
                        }
                        style={
                            activeSidebar === "propertyTypeAllSidebar" ||
                            pageName[1] === "propertyType" ||
                            pageName[1] === "groupRegionalManagers"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/propertyType");
                            }}
                        >
                            <span>Property Chain</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "groupRegionalManagers"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "propertyTypeAllSidebar" ||
                            pageName[1] === "propertyType" ||
                            pageName[1] === "groupRegionalManagers"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/groupRegionalManagers");
                            }}
                        >
                            <span>Revenue/Hotel Managers</span>
                        </a>
                    </li>
                    {/* Property Chain Section Ends */}
                    <li
                        className={
                            activeSidebar === "appImagesAllSidebar" ||
                            pageName[1] === "all-banners" ||
                            pageName[1] === "add-banner" ||
                            pageName[1] === "all-images"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                handleActiveSidebar("appImagesAllSidebar")
                            }
                        >
                            <i className="fa fa-building"></i>
                            <span>App Images </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "all-banners" ||
                            pageName[1] === "add-banner"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "appImagesAllSidebar" ||
                            pageName[1] === "all-banners" ||
                            pageName[1] === "add-banner" ||
                            pageName[1] === "all-images"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/all-banners");
                            }}
                        >
                            <span>Best Deals</span>
                        </a>
                    </li>
                    <li
                        className={pageName[1] === "all-images" ? "active" : ""}
                        style={
                            activeSidebar === "appImagesAllSidebar" ||
                            pageName[1] === "all-banners" ||
                            pageName[1] === "add-banner" ||
                            pageName[1] === "all-images"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/all-images");
                            }}
                        >
                            <span> More for You</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "customers" ||
                            pageName[1] === "customerRecords"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/customers");
                            }}
                        >
                            <i className="fa fa-user" />
                            <span>Customers</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "reservations" ||
                            pageName[1] === "reservationDetails"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/reservations");
                            }}
                        >
                            <i
                                className="fa fa-suitcase"
                                style={{ fontSize: 14 }}
                            />
                            <span>Reservations</span>
                        </a>
                    </li>

                    {/* No Shows Section Starts */}
                    <li
                        className={
                            activeSidebar === "noShowAllSidebar" ||
                            pageName[1] === "noShow"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleActiveSidebar("noShowAllSidebar");
                            }}
                        >
                            <i className="fa fa-file"></i>
                            <span>No Shows </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={pageName[2] === "approved" ? "active" : ""}
                        style={
                            activeSidebar === "noShowAllSidebar" ||
                            pageName[2] === "approved" ||
                            pageName[2] === "unapproved"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/noShow/approved");
                            }}
                        >
                            <span>Approved</span>
                        </a>
                    </li>
                    <li
                        className={pageName[2] === "unapproved" ? "active" : ""}
                        style={
                            activeSidebar === "noShowAllSidebar" ||
                            pageName[2] === "approved" ||
                            pageName[2] === "unapproved"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/noShow/unapproved");
                            }}
                        >
                            <span>Un-Approved</span>
                        </a>
                    </li>
                    {/* No Shows Section Ends */}

                    <li
                        className={
                            pageName[1] === "pricing"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/pricing");
                            }}
                        >
                            <i
                                className="fa fa-money"
                                style={{ fontSize: 15 }}
                            />
                            <span>Pricing</span>
                        </a>
                    </li>
                    {/* Logs Section Starts */}
                    <li
                        className={
                            activeSidebar === "logsSidebar" ||
                            pageName[1] === "logs"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleActiveSidebar("logsSidebar");
                            }}
                        >
                            <i className="fa fa-history"></i>
                            <span>Logs</span> <span className="fa arrow"></span>
                        </a>
                    </li>
                    <li
                        className={pageName[2] === "rate-gain" ? "active" : ""}
                        style={
                            activeSidebar === "logsSidebar" ||
                            pageName[1] === "logs"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/logs/rate-gain");
                            }}
                        >
                            <span>Rate Gain</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[2] === "cancellation-period"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "logsSidebar" ||
                            pageName[1] === "logs"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/logs/cancellation-period");
                            }}
                        >
                            <span>Cancellation Period</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[2] === "exchange-rate-gain"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "logsSidebar" ||
                            pageName[1] === "logs"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/logs/exchange-rate-gain");
                            }}
                        >
                            <span>OpenExchange Rate</span>
                        </a>
                    </li>
                    {/* Logs Section Ends */}
                    <li
                        className={
                            pageName[1] === "vatgst"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/vatgst");
                            }}
                        >
                            <i
                                className="fa fa-archive"
                                style={{ fontSize: 15 }}
                            />
                            <span>Countries</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "exchange-rate-history"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/exchange-rate-history");
                            }}
                        >
                            <i
                                className="fa fa-history"
                                style={{ fontSize: 15 }}
                            />
                            <span>Exchange Rate History</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "archives"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/archives");
                            }}
                        >
                            <i
                                className="fa fa-archive"
                                style={{ fontSize: 15 }}
                            />
                            <span>Archives</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "users-all"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/users-all");
                            }}
                        >
                            <i
                                className="fa fa-users"
                                style={{ fontSize: 15 }}
                            />
                            <span>All users</span>
                        </a>
                    </li>
                    <li
                        className={
                            pageName[1] === "execute-billing"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active "
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                setActiveSidebar("");
                                handleRedirect("/execute-billing");
                            }}
                        >
                            <i
                                className="fa fa-exchange"
                                style={{ fontSize: 15 }}
                            />
                            <span>Execute Billing</span>
                        </a>
                    </li>
                </ul>
                {/* <div className="sidebar-widgets">
                    <a
                        href="/addHotel"
                        className="btn btn-primary"
                        rel="noreferrer"
                    >
                        <i className="fa fa-plus c-warning" /> Add Hotel
                    </a>
                    <a
                        href="/addProperty"
                        className="btn btn-primary"
                        rel="noreferrer"
                    >
                        <i className="fa fa-plus c-warning" /> Add Property
                    </a>
                </div> */}
                <div className="sidebar-footer clearfix">
                    <div
                        className="sidebar-widgets"
                        style={{ marginBottom: 0, padding: 14 }}
                    >
                        <a
                            href="https://analytics.google.com/analytics/"
                            className="btn btn-danger"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i
                                className="fa fa-bar-chart c-warning"
                                style={{ color: "#fff" }}
                            />
                            Google Analytics
                        </a>
                    </div>
                    <a
                        href={() => null}
                        className="pull-left cursor-pointer"
                        onClick={lockScreen}
                        data-rel="tooltip"
                        data-placement="top"
                        data-original-title="Lockscreen"
                    >
                        <i className="icon-lock" />
                    </a>
                    <a
                        href={() => null}
                        className="pull-left btn-effect cursor-pointer"
                        onClick={logout}
                        data-modal="modal-1"
                        data-rel="tooltip"
                        data-placement="top"
                        data-original-title="Logout"
                    >
                        <i className="icon-power" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
