import $api from "../../utils/axios";
import axios from "axios";
import { toast } from 'react-toastify';
import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function BannerListing() {
    const history = useHistory();
    const [rows, setRows] = React.useState([]);

    const [options, setOptions] = React.useState([]);
  
    const [hotelname, setHotelname] = React.useState();

    const [bannerText, setbannerText] = React.useState();

    const [addedAtBeginning, setAddedAtBeginning] = React.useState(false);

    React.useEffect(() => {
      (async () => {
        const resHotel = await $api.post("/banners/hotels");
        const newElement = [{_id: '0', name: 'View All'}, ...resHotel.data.data];
        //resHotel.data.data.unshift({_id: '0', name: 'View All'});
        if (!addedAtBeginning) {
        setOptions(newElement);
        setAddedAtBeginning(true); // Mark that the operation has been done
      }
      if(options && addedAtBeginning === true) {
        const res = await $api.get(`/banners/${options[0]._id}`);
        setRows(res.data);
        setHotelname(options[0].name);
      }
      })();
    }, [addedAtBeginning, options]);

    const handleOptionChange = async (event, selectedOption) => {
        if(selectedOption !== null) {
        let resnew = await $api.get(`/banners/${selectedOption._id}`);
        setRows(resnew.data);
        if(resnew.data.length === 0) {
        setbannerText('Sorry, no records found.');
        }
        }
        if(selectedOption === null) {
          let resnew = await $api.get(`/banners/0`);
          setRows(resnew.data);
          if(resnew.data.length === 0) {
            setbannerText('Sorry, no records found.');
          }        
          }
        //setCurrentTab(res.data[0]._id);
      };

  const handleDeleteClick = async (param) => {
    let parameters = {
      "hotelId": param,
      "isDeleted": true,
  };
  await axios.post(`${process.env.REACT_APP_API_URL}/banners/deletebanner`, parameters, {
      headers: {
          'Content-type': 'application/json',
      },
  }).then(managerResponse => {
    setRows(managerResponse.data);
    toast.success('Banner Deleted Successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
})
.catch(error => {
    console.log(error);
})
    // Perform actions with the parameter here
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(rows);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setRows(newItems);

    // Save the new order to MongoDB
    saveOrderToMongoDB(newItems); // Implement this function
  };

  const saveOrderToMongoDB = async (newItems) => {
    // Implement your logic to save newItems order to MongoDB
    newItems.map((item, index) => {
      item.sequenceOrder = index + +1;
      return item;
    });

  await axios.post(`${process.env.REACT_APP_API_URL}/banners/sequencebanner`, newItems, {
      headers: {
          'Content-type': 'application/json',
      },
  }).then(managerResponse => {
    setRows(managerResponse.data);
    toast.success('Banner Sequence has been changed Successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    window.location.reload();
})
.catch(error => {
    console.log(error);
})
    // Send a request to your backend to update the order in the database
  };

  return (
    <div>
    <div className="header">
      <h2><strong>Manage</strong> Banners</h2>
      <div className="breadcrumb-wrapper">
        <ol className="breadcrumb">
          <li><a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
          </li>
          <li className="active">Banner Management</li>
        </ol>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="panel">
          <div className="panel-header ">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <h3><strong>Banner Listing</strong> </h3>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                <a  href={() => null} onClick={() => history.push("/add-banner")} className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" /> Add Banner</a>
              </div>
            </div>
          </div>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <div>
        </div>
        <div className="autoselect" style={{ position: 'right' }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            onChange={handleOptionChange}
            renderInput={(params) => <TextField {...params} label={hotelname} />}
          />
        </div>
      </Grid>
        <div className="content">
            <div>
        <DragDropContext onDragEnd={onDragEnd}>
        <table style={{marginLeft: '50px', marginTop: '20px'}}>
            <thead>
                <tr>
                    <th style={{width: '350px', paddingTop: '10px'}}>SEQUENCE NO.</th>
                    <th style={{width: '350px', paddingTop: '10px'}}>PROPERTY NAME</th>
                    <th style={{width: '350px', paddingTop: '10px'}}>BANNER IMAGE</th>
                    <th style={{width: '350px', paddingTop: '10px'}}>ACTION</th>
                </tr>
            </thead>
        <Droppable droppableId="tbody">
          {(provided) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {rows.length > 0 && rows.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided) => (
                    <tr                       
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      >
                                <td style={{width: '350px', paddingTop: '10px'}}>{item.sequenceOrder}</td>
                                <td style={{width: '350px', paddingTop: '10px'}}>{item.name}</td>
                                <td style={{width: '350px', paddingTop: '10px'}}><img src={item.photos[0]} alt="Banner" style={{ height: "30%", width: "80%" }} /></td>
                                <td style={{width: '350px', paddingTop: '10px'}}><button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={() => handleDeleteClick(item._id)}>Delete</button></td>
                            </tr>
                  )}
                </Draggable>
              ))}
              {rows.length === 0 && (<tr><td style={{width: '350px', paddingTop: '30px', paddingBottom: '30px'}}><p>{bannerText}</p></td></tr>)}
              {provided.placeholder}
              </tbody>
          )}
        </Droppable>
        </table>
      </DragDropContext>             
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  );
}
