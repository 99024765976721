import $api from "../../utils/axios";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import moment from "moment";

export default function CancellationPeriod() {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await $api.get("/logs/cancellation-period");
      setRows(res.data);
    })();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => [
    {
      headerName: "Booking",
      field: "transferred",
      type: "string",
      width: 250,
      renderCell: (params) => {
        if (params.value.length > 0 && Array.isArray(params.value)) {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
              {params.value.map((transferred, index) => (
                <a key={index} href={`/reservationDetails/${transferred._id}`}>
                  {transferred.bookingId}
                  {params.value.length - 1 === index ? null : ","}
                </a>
              ))}
            </Box>
          );
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Action",
      field: "hotelId",
      type: "string",
      width: 150,
      valueFormatter: (_) => {
        return "Confirmation";
      },
    },
    {
      headerName: "Start",
      field: "start",
      type: "date",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM HH:mm");
      },
    },
    {
      headerName: "End",
      field: "end",
      type: "date",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM HH:mm");
      },
    },
  ]);

  return (
    <Box>
      <h2>
        <strong>Logs</strong> / Cancellation Period
      </h2>
      <ul style={{ marginLeft: 20 }}>
        <li>Transfers fund to Hotel Stripe account (24 hours before check in time)</li>
        <li>Change’s reservation booking status from BOOKED to CHECKEDIN (5 minutes after check in time)</li>
        <li>Send hotels reminder email 24 hours before</li>
        <li>Send customer reminder email 24 hours before</li>
      </ul>
      <Container
        sx={{
          backgroundColor: "white",
          my: 2,
          p: 0,
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <DataGrid
          sx={{ border: 0 }}
          getRowId={(row) => row._id}
          autoHeight
          columns={columns}
          rows={rows}
        />
      </Container>
    </Box>
  );
}
