import React, { useEffect } from "react";
import $api from "../utils/axios";
import { map } from "lodash";

export default function useHotel() {
  const [hotels, setHotels] = React.useState([]);

  useEffect(() => {
    (async () => {
      const res = await $api.get("/hotel/list");
      setHotels(map(res.data, (r) => ({ _id: r._id, name: r.name, commission: r.commission?._id })));
    })();
  }, []);

  return { hotels };
}
